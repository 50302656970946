import React, { useState } from 'react'
import { Modal, Button } from 'rsuite'
import SignUpModal from '../auth/SignUp'
import SignInModal from '../auth/SignIn'
import { useTranslation } from 'react-i18next'
const halyk = require('../payment-api')

const WelcomeModal = (props) => {
  const { t } = useTranslation()
  const [signUpShow, setSignUpShow] = useState(false)
  const [signInShow, setSignInShow] = useState(false)
  const onSignUpClicked = () => {
    setSignUpShow(true)
    props.onHide()
  }
  const onSignInClicked = () => {
    setSignInShow(true)
    props.onHide()
  }
  const handleDemoClick = () => {
    sessionStorage.setItem('demo', true)
    props.onHide()
  }

  return (
    <div className='show-grid'>

      <Modal {...props} show={props.show} backdrop={true} keyboard={false}>
        <Modal.Header closeButton={true}>
          <Modal.Title className='text-center'>
            {t('Welcome.welcome_sunkarsat_title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {t('Welcome.welcome_sunkarsat_message')}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance='primary' size='sm' onClick={onSignUpClicked}>{t('Auth.Signup.sign_up')}</Button>
          <Button appearance='primary' color='orange' size='sm' onClick={onSignInClicked}>{t('Auth.Signin.sign_in')}</Button>
          {/* <Button appearance='primary' color='green' size='sm' onClick={handleDemoClick}>{t('Auth.Signin.demo')}</Button> */}
        </Modal.Footer>
      </Modal>
      <SignUpModal show={signUpShow} onHide={() => setSignUpShow(false)} closeTour = {props.closeTour}/>
      <SignInModal show={signInShow} onHide={() => setSignInShow(false)} />
    </div>

  )
}

export default WelcomeModal
