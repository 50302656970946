import { Table } from 'rsuite'

const OrderIDCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table

  return (
    <Cell {...props}>
      <div>
        <p>{rowData.id}</p>
        {/* <small>
          <span
            style={{
              color: rowData.is_done
                ? 'green'
                : rowData.is_canceled
                  ? 'red'
                  : 'orange'
            }}
          >
            {
            rowData.is_done
              ? 'Done'
              : rowData.is_canceled
                ? 'Canceled'
                : 'In process'
          }
          </span>
        </small> */}
      </div>
    </Cell>
  )
}

export default OrderIDCell
