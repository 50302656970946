import React, { createContext, useState } from 'react'

export const AuthenticationContext = createContext()

const AuthenticationContextProvider = (props) => {
  const [user, setUser] = useState('')
  return (
    <AuthenticationContext.Provider
      value={{
        user,
        setUser
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationContextProvider
