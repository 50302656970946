import React, { useContext, useState } from 'react'
import { Sidenav, Icon, Badge, IconButton, Button, ButtonGroup, Whisper, Tooltip } from 'rsuite'
import NavToggle from './NavToggle'
import { NavigationContext } from '../../contexts/NavigationContext'
import { SatImgContext } from '../../contexts/DataProviderContext'
import { useTranslation } from 'react-i18next'
// import { Link, Route, useHistory } from 'react-router-dom'

const SideNavMenu = ({ expand, handleExpand, closeTour, setModalShow }) => {
  // const history = useHistory()
  const { t } = useTranslation()
  const { setNavItemId, navItemId } = useContext(NavigationContext)
  const { chosenTiles } = useContext(SatImgContext)

  const [activeKey, setActiveKey] = useState(false)
  const handleNavClick = (navId) => {
    // eslint-disable-next-line no-restricted-globals
    if ((navId === 1 || navId === 3) && !localStorage.token && location.hostname !== 'localhost1') {
      setModalShow(true)
    } else {
      if (!expand) handleExpand()
      setNavItemId(navId)
    }
  }

  const TooltipIconButton = ({ tip, placement, icon, navId }) => {
    return (
      <Whisper
        trigger='hover'
        placement={placement}
        speaker={
          <Tooltip>
            {tip}
          </Tooltip>
        }
      >
        {
          (navId < 4)
            ? <IconButton appearance='subtle' icon={<Icon icon={icon} size='lg' />} onClick={() => handleNavClick(navId)} className={ navItemId === navId && 'active-icon' }/>
            : (navId === 4)
                ? (
                <Button appearance='subtle' onClick={() => handleNavClick(navId)} className={ navItemId === navId && 'active-icon' }>
                  {chosenTiles.length !== 0
                    ? <Badge content={chosenTiles.length}>
                      <Icon icon={icon} />
                    </Badge>
                    : <Icon icon={icon} />
                  }
                </Button>
                  )
                : (navId === 5)
                    ? (
                      <a className='full b-0' target='_blank' href='https://www.gharysh.kz/saytru2022/okompanii/%20contactru/' rel='noreferrer'>
                <Button appearance='subtle'>
                    <Icon icon={icon} />
                    </Button>
                  </a>
                      )
                    : <Button className={ navItemId === navId && 'active-icon' } appearance='subtle' onClick={() => { closeTour(true); handleNavClick(navId) }}><Icon icon={icon} /></Button>

        }
      </Whisper>
    )
  }

  return (
    <Sidenav className='blacked' style={{ height: window.innerHeight }}>
      {/* // FIXME: height is longer than viewport  when collapsed */}
      <NavToggle expand={expand} onChange={handleExpand} />
      <Sidenav
        expanded={false}
        activeKey={activeKey}
        onSelect={(activeKey) => setActiveKey(activeKey)}
        appearance='subtle'
      >
        <Sidenav.Body className='text-center'>
          <ButtonGroup vertical className='m-t-12'>
            <TooltipIconButton tip={t('Nav.home')} placement='left' icon='dashboard' navId={0} />
            <TooltipIconButton tip={t('Nav.orders')} placement='left' icon='history' navId={1} />
            <TooltipIconButton tip={t('Nav.search')} placement='left' icon='search' navId={2} />
            <TooltipIconButton tip={t('Nav.request')} placement='left' icon='order-form' navId={3} />
            <TooltipIconButton tip={t('Nav.cart')} placement='left' icon='shopping-cart' navId={4} />
            <div id='sixth-step'>
            <TooltipIconButton tip={t('Nav.help')} placement='left' icon='help-o' navId={5} />
            <TooltipIconButton tip={t('Nav.tour')} placement='left' icon='map' navId={6} />
            </div>
          </ButtonGroup>
        </Sidenav.Body>
      </Sidenav>
    </Sidenav>
  )
}

export default SideNavMenu
