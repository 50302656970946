import React, { createContext, useState } from 'react'
import * as turf from '@turf/turf'
import moment from 'moment'
// import TestContext from './TestContext'

export const SearchOptionsContext = createContext()

const SearchOptionsContextProvider = (props) => {
  const [searchOptions, setSearchOptions] = useState({
    satellite: 'KazEOSat-1',
    date_from: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
    date_to: moment(new Date()).format('YYYY-MM-DD'),
    geometry: {},
    cloud_coverage: 100
  })

  const [jsonLayers, setJsonLayers] = useState()
  const [shareTile, setShareTile] = useState()
  const [newOrder, setNewOrder] = useState(false)

  const geoJsonParser = (data) => {
    // converting different geometry types (LineString, Point, etc) into polygon
    const convertToPolygon = (el) => {
      let converted = {}
      let options = {}
      const geometry = el.geometry || el
      const coordinates = geometry.coordinates
      if (geometry.coordinates.length === 0) {
        throw new Error('Empty geometry.')
      }
      switch (geometry.type) {
        case 'LineString':
          converted = turf.bboxPolygon(turf.bbox(geometry)).geometry
          break
        case 'Point':
          options = {
            steps: 5,
            units: 'kilometers'
          }
          converted = turf.circle(coordinates, 20, options)
          break
        default:
          break
      }
      return converted
    }

    // converting nested coordinates into regular ones
    const convertedFeatures = data.features.map((feature) => {
      if (feature.geometry.geometries) {
        return feature.geometry.geometries.map(geometry => {
          if (geometry.type === 'Polygon') {
            return geometry
          } else {
            return convertToPolygon(geometry)
          }
        })
      } else if (feature.geometry.type !== 'Polygon') {
        return convertToPolygon(feature)
      } else {
        return feature
      }
    })

    // getting coordinates for multipolygons
    const multiPolygonCoords = convertedFeatures.reduce((acc, feature) => {
      if (!Array.isArray(feature)) {
        acc = [...acc, turf.getCoords(feature)]
      } else {
        acc = [...acc, ...feature.map(geometry => turf.getCoords(geometry))]
      }
      return acc
    }, [])

    return turf.union(turf.multiPolygon(multiPolygonCoords), turf.multiPolygon(multiPolygonCoords))
  }
  return (
    <SearchOptionsContext.Provider value={{
      searchOptions,
      setSearchOptions,
      jsonLayers,
      setJsonLayers,
      newOrder,
      setNewOrder,
      geoJsonParser,
      shareTile,
      setShareTile
    }}
    >
      {props.children}
    </SearchOptionsContext.Provider>
  )
}

export default SearchOptionsContextProvider
