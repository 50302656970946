import { useEffect, useState } from 'react'
import { Sidenav, Panel } from 'rsuite'
import OrdersTable from './orderhisltoryelements/OrderTable'
import API from '../../api'
import { useTranslation } from 'react-i18next'

const OrdersHistorySidenav = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [params] = useState({
    page: 1,
    size: 20,
    filter: 'all',
    order: 'desc'
  })
  useEffect(() => {
    console.log(sessionStorage.token)
    if (sessionStorage.token || localStorage.token) {
      const fetchData = async () => {
        const result = await API.order.get_orders(params)
        setData(result)
      }
      fetchData()
    }
  }, [params])
  return (
    <Sidenav appearance='subtle'>
      <Sidenav.Body>
        <Panel style={{ height: props.height * 9.9 / 10 }} id='fifth-step'>
          <h5 className='p-15'>{t('History.title')}</h5>
          {(data.total > 0) ? <OrdersTable data={data.orders} height={props.height} /> : <p>{t('Errors.empty_data')}</p>}
        </Panel>
      </Sidenav.Body>

    </Sidenav>
  )
}

export default OrdersHistorySidenav
