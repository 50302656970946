/* eslint-disable prefer-regex-literals */
import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, FlexboxGrid, Input, Whisper, Tooltip, Alert, Icon, InputGroup, Checkbox, IconButton } from 'rsuite'
import { login } from '../actions/auth'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ResetPasswordModal from './ResetPasswordModal'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'

const SignInModal = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        document.getElementById('submitButton') && document.getElementById('submitButton').click()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')

  // const [ errors, setErrors ] = useState([])
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [passwordModal, setPasswordModalShow] = useState(false)

  const onSubmit = () => {
    const tempErrors = validate(data)
    // setErrors(temp_errors);
    console.log(data)
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      props.login(data, rememberMe)
        .then(res => {
          setLoading(false)
          if (res && !res.success) {
            Alert.error(t('Auth.Signin.check') + '\n' + res.error.message)
          } else {
            Alert.success(t('Welcome.Welcome') + ` ${res.user.first_name}!`)
            window.localStorage.setItem('token', res.auth_token)
            // localStorage.setItem('token', res.auth_token)
            // console.log(localStorage)
            props.onHide()
          }
          sessionStorage.removeItem('pop_url')
        })
    } else {
      Alert.error(t('Auth.Signin.check'))
    }
  }

  const onBack = () => {

  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('Auth.Signin.errors.empty.email')
    }
    if (!data.password) {
      errors.password = t('Auth.Signin.errors.empty.password')
    }
    return errors
  }

  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }

  const handlePasswordReset = () => {
    setPasswordModalShow(true)
    props.onHide()
  }

  return (
    <div className='show-grid'>

      <Modal {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('Auth.Log_in')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder='Email*' id="email" onChange={e => setData({
                    ...data,
                    email: e
                  })} size='md'
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                  <InputGroup inside>
                    <Input
                      placeholder='Password*' size='md'
                      id="password"
                      type={passwordType}
                      onChange={e => setData({
                        ...data,
                        password: e
                      })}
                    />
                    <InputGroup.Addon>
                      <Icon link icon={eye} onClick={togglePassword} />
                    </InputGroup.Addon>
                  </InputGroup>
                <div className='text-center text-underline'>
                  <br />
                  <p className='d-sign-clickable'onClick={handlePasswordReset}>{t('Auth.Password.forgot_message')}</p>
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Checkbox onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} className='form-field-checkbox'>{t('Auth.Signin.remember_me')} </Checkbox>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/'>
              <IconButton appearance='ghost' size='sm' className='m-r-15 m-b-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button
            appearance='primary' size='sm' id='submitButton' onClick={onSubmit} loading={loading} className='m-r-15 m-b-15'
          >
            {t('Auth.Signin.sign_in')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ResetPasswordModal show={passwordModal} onHide={() => setPasswordModalShow(false)} />
    </div>
  )
}
SignInModal.propTypes = {
  login: PropTypes.func.isRequired
}

export default connect(null, { login })(SignInModal)
