import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { userLoggedIn } from './actions/auth'
import axios from 'axios'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'leaflet-geosearch/dist/geosearch.css'
import './index.css'

// TODO: get rid of unneccessary redux
// require('dotenv').config()
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

// TODO: rewrite with the Cokkie storage
if (localStorage.token && localStorage.user) {
  const data = JSON.parse(localStorage.user)
  store.dispatch(userLoggedIn(data))
  // console.log(localStorage)
  if (localStorage.token.indexOf('Bearer') === -1) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`
  } else {
    axios.defaults.headers.common.Authorization = localStorage.token
  }
  // axios.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`
} else if (sessionStorage.token && sessionStorage.user) {
  const data = JSON.parse(sessionStorage.user)
  store.dispatch(userLoggedIn(data))
  if (sessionStorage.token.indexOf('Bearer') === -1) {
    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.token}`
  } else {
    axios.defaults.headers.common.Authorization = sessionStorage.token
  }
  // axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.token}`
} else {
  if (sessionStorage.token && sessionStorage.token.indexOf('Bearer') === -1) {
    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.token}`
  } else {
    axios.defaults.headers.common.Authorization = sessionStorage.token
  }
  // axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.token}`
}

ReactDOM.render(
  <Suspense fallback={(<div>Loading</div>)}>
    {/* <React.StrictMode> */}
    <React.Suspense fallback='loading'>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>
    {/* </React.StrictMode> */}
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
