import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HOST_URL, SCENE_SETTINGS } from '../../../constants/constants'
import { Table, Checkbox, Icon, IconButton } from 'rsuite'
import API from '../../../api'
import ImageCell from '../../elements/cellstable/ImageCell'
import CheckCell from '../../elements/cellstable/CheckCell'
import PriceCell from '../../elements/cellstable/PriceCell'
import { SatImgContext } from '../../../contexts/DataProviderContext'
import NameCell from '../../elements/cellstable/NameCell'
import ActionCell from '../../elements/cellstable/ActionCell'

const CartItemsTable = (props) => {
  const summ = props.summ
  const setSumm = props.setSumm
  const { t } = useTranslation()
  const { Column, HeaderCell, Cell, NumberCell, Pagination } = Table
  const rowKey = 'id'
  const checked = false
  const indeterminate = false

  const { chosenTiles, setChosenTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  const [checkedKeys, setCheckedKeys] = useState([])
  const [isActive, setActive] = useState(false)

  const HeaderSummary = ({ title, summary }) => (
    <div className='text-right'>
      <label>{title}</label>
      <div>
        <b className='color-highlight'>{summary?.sumKZT !== 0 && new Intl.NumberFormat('kz-KZ', { style: 'currency', currency: 'KZT', minimumFractionDigits: 2 }).format(summary.sumKZT)}</b>
        <br />
        <small>{summary?.sumUSD !== 0 && new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(summary.sumUSD)}</small>
      </div>
    </div>
  )

  const handleRowClick = data => {
    if ((activeTile?.id === data.id) && (!isActive)) {
      setActive(false)
    } else {
      setActive(true)
      setActiveTile(data, isActive)
    }
  }

  const handleCheckAll = (value, checked) => {
    const checkedKeys = checked ? chosenTiles.map(item => item.id) : []
    setCheckedKeys(checkedKeys)
  }
  const handleCheck = (value, checked) => {
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value)

    setCheckedKeys(nextCheckedKeys)
  }

  useEffect(() => {
    let countKZT = 0
    let countUSD = 0

    chosenTiles.forEach(item => {
      countKZT += parseFloat(item.price_kzt)
      countUSD += parseFloat(item.price_usd)
    })
    setSumm({
      ...summ,
      sumKZT: countKZT,
      sumUSD: countUSD
    })
  }, [chosenTiles])

  return (
    <Table
      height={props.height}
      width={400}
      headerHeight={70}
      data={chosenTiles}
      rowKey={rowKey}
      rowHeight={60}
      flexGrow
      onRowClick={handleRowClick}
      style={{ background: 'transparent' }}
    >
      <Column width={40} className='centered' style={{ padding: '0 0 0 0' }}>
        <HeaderCell>
          <Checkbox
            // inline
            checked={checked}
            indeterminate={indeterminate}
            onChange={handleCheckAll}
          />
        </HeaderCell>
        <CheckCell
          dataKey='id'
          data={chosenTiles}
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          onChange={handleCheck}
        />
      </Column>

      <Column width={60} align='left'>
        <HeaderCell style={{ padding: '20px 0 0 0' }}>{t('Common.preview')}</HeaderCell>
        <ImageCell style={{ padding: '0 0 0 0' }} dataKey='quicklook_url' />
      </Column>

      <Column width={90}>
        <HeaderCell style={{ padding: '20px 0 0 0' }}>{t('Common.id')} #</HeaderCell>
        <NameCell style={{ padding: '0 0 0 0' }} dataKey='id' />
      </Column>

      <Column width={110} align='right'>
        <HeaderCell>
          <HeaderSummary title={t('Common.total_price')} summary={summ} />
        </HeaderCell>
        <PriceCell dataKey='price_kzt' />
      </Column>

      <Column width={100}>
        <HeaderCell>{t('Common.action')}</HeaderCell>
        {/* <ActionCell dataKey='id' /> */}
        <Cell>
          {rowData => {
            const handleAction = () => {
              setChosenTiles(() => {
                const scenes = chosenTiles.filter(scene => (scene.id !== rowData.id))
                return scenes
              })
            }
            return (
              <span>
                <IconButton size='xs' icon={<Icon icon='trash' />} onClick={handleAction} />
              </span>
            )
          }}
        </Cell>
      </Column>
    </Table>
  )
}

export default CartItemsTable
