import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FlexboxGrid, Panel, Icon } from 'rsuite'

const ContactSection = () => {
  const { t } = useTranslation()
  return (
    <Panel header={<h5>{t('Common.contacts')}</h5>} bordered>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={5}>
          <div className='text-center'>
              <Icon icon='book' className='contact-icon' style={{ color: '#34c3ff' }}/>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19}>
          <p className = 'contact-div'>{t('Contact.bin')}</p>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <br />
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={5}>
          <div className='text-center'>
              <Icon icon='map-marker' className='contact-icon' style={{ color: '#34c3ff' }}/>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19}>
          <p className = 'contact-div'>{t('Contact.address')}</p>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <br />
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={5}>
          <div className='text-center'>
              <Icon icon='bank' className='contact-icon' style={{ color: '#34c3ff' }}/>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19}>
          <p className = 'contact-div'>{t('Contact.name')}</p>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <br />
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={5}>
          <div className='text-center'>
              <Icon icon='phone' className='contact-icon' style={{ color: '#34c3ff' }}/>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19}>
          <p className = 'contact-div'>{t('Contact.phone')}</p>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <br />
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={5}>
          <div className='text-center'>
              <Icon icon='at' className='contact-icon' style={{ color: '#34c3ff' }}/>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19}>
          <p className = 'contact-div'><a href='mailto:kense@gharysh.kz' target='_blank' rel='noreferrer'>kense@gharysh.kz</a> {t('Contact.mail')}</p>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  )
}

export default ContactSection
