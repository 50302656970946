import { combineReducers } from 'redux'
import user from './reducers/user'
import users from './reducers/users'
import layer from './reducers/layer'
import cart from './reducers/cart'
import orders from './reducers/orders'
import prices from './reducers/prices'
import quicklooks from './reducers/quicklooks'
import error from './reducers/error'
import store from './reducers/store'

export default combineReducers({
  user,
  layer,
  quicklooks,
  cart,
  orders,
  prices,
  users,
  error,
  store
})
