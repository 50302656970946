import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FlexboxGrid, Panel, Icon } from 'rsuite'

const HelpSection = () => {
  const { t } = useTranslation()
  return (
    <Panel bordered className='force-index'>
          <p>
          {t('Offer.title')}<br />
          <a href = 'files/Sunkarsat_public_offer_agreement.pdf' target='_blank' rel='noreferrer'>{t('Offer.file1')}</a><br />
          <a href = 'files/Sunkarsat_user_information_safety_agreement.pdf' target='_blank' rel='noreferrer'>{t('Offer.file2')}</a><br />
          <a href = 'files/Sunkarsat_privacy_policy.pdf' target='_blank' rel='noreferrer'>{t('Offer.file3')}</a><br />
          </p>
    </Panel>
  )
}

export default HelpSection
