import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from 'rsuite'
import UploadFilesModal from './UploadFilesModal'
import { NavigationContext } from '../contexts/NavigationContext'
const UploadKML = (props) => {
  const { t } = useTranslation()
  const { setNavItemId } = React.useContext(NavigationContext)
  const [modalShow, setModalShow] = React.useState(false)
  const [area, setArea] = React.useState()
  const revealFunc = () => {
    setModalShow(true)
    setNavItemId(3)
  }
  return (
    <div style={ props.isVisible ? { marginTop: '10px' } : { display: 'none' } }>
      <div className='centered-block'>
      <Button id={props.id} onClick={revealFunc}><Icon icon={'upload'}/> {t('Common.upload')}</Button>
      </div>
      <div id={props.id} style={area ? { top: '5%' } : { display: 'none' }}>{area ? `Площадь: ${area} га` : t('Common.upload')}</div>
      <UploadFilesModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setArea = {setArea}
      />
    </div>
  )
}
export default UploadKML
