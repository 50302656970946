
import { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Modal, Button, Alert } from 'rsuite'
import { useTranslation, Trans } from 'react-i18next'
import API from '../../../api'

const NewOrderConfirmingModal = ({ show, hide, order, isRequested, setIsRequested }) => {
  const { t } = useTranslation()
  const [newOrderData, setNewOrderData] = useState(null)

  const handleSubmit = async event => {
    event.preventDefault()
    // setCalculateOnly(() => { return false })
    const fetchDataNewOrder = async () => {
      const result = await API.cart.submit_cart()
      if (result.success) {
        Alert.success(t('Order.Confirmation.success'))
      } else {
        console.log(JSON.parse(result))
        // Alert.error(result.error.message)
      }
    }
    fetchDataNewOrder()
  }

  // useEffect(() => {
  //   setIsRequested(() => { return true })
  // }, [])

  const handleCalculate = () => {
    const fetchDataPrice = async (params) => {
      const response = await API.cart.cart_new(params)
      console.log(response)
      if (response.success) {
        setNewOrderData(() => {
          return {
            area: response.area,
            price_kzt: response.price_kzt,
            price_usd: response.price_usd
          }
        })
      }
    }
    fetchDataPrice(order)
  }

  // useEffect(() => {
  //   const fetchDataPrice = async (params) => {
  //     const response = await API.cart.cart_new(params)
  //     if (response.success) {
  //       setPreorderingData({
  //         area: response.area,
  //         kzt: response.price_kzt,
  //         usd: response.price_usd
  //       })
  //     } else {
  //       Alert.error(response)
  //     }
  //   }
  //   fetchDataPrice(orderRequest)
  // }, [order])

  return show
    ? ReactDOM.createPortal(
      <Modal show={show} onHide={hide}>
        <Modal.Header>
          <Modal.Title>{t('Order.Confirmation.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans
            i18nKey='Order.Confirmation.message'
            values={{
              resolution: order.resolution === 'HIGH' ? t('Order.Confirmation.high') : t('Order.Confirmation.medium'),
              date_from: order.periods[0], // order.periods.start,
              date_to: order.periods[1],
              cloudCoverageMin: 0,
              cloudCoverageMax: order.cloudCoverage,
              processingLevel: order.processingLevel,
              priority: t(`consts.priorities.${order.priority}`),
              pitchMin: -15,
              pitchMax: order.pitch,
              rollMin: -35,
              rollMax: order.roll,
              yawMin: 0,
              yawMax: order.yaw
            }}
            defaults='Your order for <bold>{{ resolution }}</bold> resolution <br/>
              From {{date_from}} to {{date_to}}<br />
              Cloud requirements from ({{cloudCoverageMin}}%) to ({cloudCoverageMax}%)<br />
              Processed to a level of <italic>{{processingLevel}}</italic><br />
              Priority {{priority}}<br />
              Pitch ({{pitch[0]}}°) to ({{pitch[1]}}°)<br />
              Roll from ({{roll[0]}}°) to ({{roll[1]}}°)<br />
              Yaw from ({{yaw[0]}}°) to ({{yaw[1]}}°)<br />'
            components={{ italic: <i />, bold: <strong /> }}
          />
          <br />
          {newOrderData &&
            <p>
              {t('Order.Confirmation.area')}
              <b>{new Intl.NumberFormat().format(newOrderData.area)} km²</b><br />
              {t('Order.Confirmation.cost')}
              <b>{new Intl.NumberFormat('kz-KZ', { style: 'currency', currency: 'KZT', minimumFractionDigits: 2 }).format(newOrderData.price_kzt)}</b>
              (USD <b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(newOrderData.price_usd)}</b>)
            </p>}
          {/* {
                (order?.area === '')
                  ? ''
                  : `Requesting order has a total area of
                    ${new Intl.NumberFormat().format(order.area)} km² and will cost
                    ${new Intl.NumberFormat('kz-KZ', { style: 'currency', currency: 'KZT', minimumFractionDigits: 2 }).format(order.price_kzt)}
                    (USD
                    ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(order.price_usd)}
                  )`
              } */}

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCalculate} appearance='primary'>
            {/* {t('Common.ok')} */}
            {t('Order.confirmation.calculate')} </Button>
          <Button onClick={handleSubmit} appearance='primary'>
            {t('Common.ok')}
          </Button>
          <Button onClick={hide} appearance='subtle'>
            {t('Common.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>,
      document.body)
    : null
}

export default NewOrderConfirmingModal
