import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader, Table } from 'rsuite'
import { BrowserRouter, Link } from 'react-router-dom'
import API from '../../../api'
import { HOST_URL } from '../../../constants/constants'
import { SatImgContext } from '../../../contexts/DataProviderContext'

const StatusCell = ({ rowData, dataKey, ...props }) => {
  const { t } = useTranslation()
  const { getDownloadLink } = useContext(SatImgContext)

  const { Cell } = Table
  const [loading, setLoading] = useState(false)
  const [scene, setScene] = useState({})
  const [id, setId] = useState(0)

  const handleDownloadClick = (id) => {
    setLoading(() => true)
    const fetchData = async () => {
      const result = await API.order.get_order(rowData.id)
      setScene(() => result?.order?.images[0])
      setId(() => result?.order?.images[0].code)
      console.log(scene.code)

      if (scene.code) {
        const res = getDownloadLink(scene.code)
        setLoading(() => false)
      }
    }
    rowData.id && fetchData()
  }

  useEffect(() => {
    console.log(id)
    if (id) {
      const res = getDownloadLink(id, setLoading)
      // setLoading(() => false)
    }
  }, [id])

  return (
    <Cell {...props}>
      <div>
        <span
          style={{
            color: rowData.is_done
              ? 'green'
              : rowData.is_canceled
                ? 'red'
                : 'orange'
          }}
        >
          {
            rowData.is_done
              ? t('Order.Status.done')
              : rowData.is_canceled
                ? t('Order.Status.cancelled')
                : t('Order.Status.processing')
          }
        </span>
        <br />
        {
          rowData.is_done &&
            <a href='#' onClick={() => handleDownloadClick(scene?.code)}>{t('Common.download')}</a>
            // <BrowserRouter>
            //   <Link to={HOST_URL + downloadLink} target='_blank' download>{t('Common.download')} </Link>
            // </BrowserRouter>
        }
        {loading && <Loader />}
      </div>
    </Cell>
  )
}

export default StatusCell
