/* eslint-disable prefer-regex-literals */
import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, InputPicker, FlexboxGrid, Input, DatePicker, Whisper, Tooltip, InputGroup, Icon, Alert, IconButton } from 'rsuite'
import { signup, login } from '../actions/auth'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'

const SignUpModal = (props) => {
  const { t } = useTranslation()
  const [purposeList] = useState([{ id: '1', text: t('consts.purpose.agri') }, { id: '2', text: t('consts.purpose.detectionalt') }, { id: '3', text: t('consts.purpose.other') }])
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    company: '',
    purpose: 'other',
    email: '',
    password: ''
  })
  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        document.getElementById('submitButton') && document.getElementById('submitButton').click()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])
  const [eye, setEye] = useState('eye')
  const [pass, setPass] = useState()
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)

  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }

  const onSubmit = async () => {
    const tempErrors = validate(data)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      // API.user.signup(data)
      //   .then(res => {
      //     setLoading(false)
      //     if (res && !res.success) {
      //       Alert.error(t('Errors.check_data'))
      //     } else {
      //       Alert.success('Please, check your email for the verification')
      //       // Alert.success(t('Auth.Registration.success'))
      //       props.onHide()
      //     }
      //   })
      await props.signup(data)
        .then(res => {
          setLoading(false)
          if (res && !res.success) {
            Alert.error(t('Errors.check_data') + '\n' + res.error.message === 'VerifyEmail' && 'Пользователь с таким адресом электронной почты')
          } else {
            props.closeTour(true)
            Alert.success(t('Auth.Registration.success'))
            // props.login({ email: data.email, password: data.password }, true)
            props.onHide()
          }
        })
    } else {
      Alert.error(tempErrors.email !== undefined ? tempErrors.email : (tempErrors.password !== undefined ? tempErrors.password : ''))
    }
  }

  const validate = (data) => {
    const errors = {}
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!data.email) {
      errors.email = t('Errors.empty_email')
    } else if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    } else if (!passwordPattern.test(data.password)) {
      errors.password = t('Errors.valid_password')
    }
    return errors
  }

  return (
    <div className='show-grid'>

      <Modal {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('Auth.create_account')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.first_name')} onChange={e => setData({
                    ...data,
                    first_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.last_name')} o onChange={e => setData({
                    ...data,
                    last_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12} className='p-15'>

                <DatePicker
                  placeholder={t('Auth.Signup.birth_date')}
                  placement='bottomStart'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.phone')} o type='text' onChange={e => setData({
                    ...data,
                    phone: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.company')} o onChange={e => setData({
                    ...data,
                    company: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <InputPicker
                  placeholder={t('Auth.Signup.purpose')} o data={purposeList} onChange={e => setData({
                    ...data,
                    purpose: e
                  })} labelKey='text' valueKey='text' block size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder='Email*' csize='md' onChange={e => setData({
                    ...data,
                    email: e
                  })}
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='focus' speaker={
                    <Tooltip>{t('Auth.Password.tooltip')}</Tooltip>
                }
                >
                  <InputGroup inside>
                    <Input
                      placeholder={t('Auth.Signup.password')} o size='md'
                      type={passwordType}
                      onChange={e => setData({
                        ...data,
                        password: e
                      })}
                    />
                    <InputGroup.Addon>
                      <Icon icon={eye} onClick={togglePassword} />
                    </InputGroup.Addon>
                  </InputGroup>
                </Whisper>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24} className='p-15'>
                <InputGroup inside>
                    <Input
                      placeholder={t('Auth.Signup.repeat_password')} o size='md'
                      type={passwordType}
                      onChange={e => {
                        setPass(e)
                      }}
                    />
                    <InputGroup.Addon>
                      <Icon icon={pass === data.password ? 'ok-circle' : 'times-circle'}/>
                    </InputGroup.Addon>
                  </InputGroup>
                  </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/'>
              <IconButton appearance='ghost' size='sm' className='m-r-15 m-b-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button
            appearance='primary' size='sm' onClick={onSubmit} loading={loading} className='m-b-15 m-r-15' id = 'submitButton'
            disabled={data.email === '' || data.password === '' || data.first_name === '' || data.last_name === '' || data.company === '' || pass !== data.password}
          >
            {t('Auth.create_account')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}

SignUpModal.propTypes = {
  signup: PropTypes.func.isRequired
}

export default connect(null, { signup, login })(SignUpModal)
// export default (SignUpModal)
