import React from 'react'
import { Table, Checkbox } from 'rsuite'

const CheckCell = ({ rowData, onChange, checkedKeys, setCheckedKeys, data, dataKey, ...props }) => {
  const { Cell } = Table

  return (
    <Cell {...props} className='p-0'>
      <Checkbox
        value={rowData[dataKey]}
        // inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </Cell>
  )
}

export default CheckCell
