import { useEffect, useState } from 'react'
import { Alert, Button, ButtonGroup, Container, Panel } from 'rsuite'
import API from '../api'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import App from '../'
import 'rsuite/dist/styles/rsuite-dark.css'
import Map from '../map/Map'
import SatImgContextProvider from '../contexts/DataProviderContext'
import SearchOptionsContextProvider from '../contexts/SearchDetailsContext'
import UploadKML from '../imports/UploadKML'
// import SidenavPanel from './components/Panel'
import SidenavPanel from '../components/SidebarPanel'
import WelcomeModal from '../components/WelcomeModal'
import NavigationContextProvider from '../contexts/NavigationContext'
import AuthenticationContextProvider from '../contexts/AuthenticationContext'
import {
  useParams,
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from 'react-router-dom'
import AccountVerification from '../auth/AccountVerification'
import SignIn from '../auth/SignIn'
import PasswordChangeModal from '../auth/PasswordChangeModal'

const CRMDetails = () => {
  // const userInfo = JSON.parse(sessionStorage.user)
  const { id } = useParams()
  const { t } = useTranslation()
  let mySuccess = false
  // const userToken = localStorage.getItem('token').split(' ')[1] || sessionStorage.getItem('token')
  // console.log('for CRM local ' + localStorage.getItem('token').split(' ')[1])
  // console.log('for CRM session ' + sessionStorage.getItem('token'))
  // axios.defaults.headers.common.Authorization = `Bearer ${userToken}`
  const data = { id: id, response: 'order details confirmed' }
  const [order, setOrder] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      // const result = await API.order.get_user_order(id) // API.order.get_order(id)
      const result = await API.order.get_order_from_all(id)
      // console.log(result)
      setOrder(() => result.order)
      // console.log(order)
      mySuccess = result.success
    }
    fetchData()
  }, [id])
  console.log(mySuccess)
  const handleConfirmation = () => {
    const fetchData = async () => {
      const result = await API.order.approve_manager_order(data)
      result.success ? Alert.success(t('Common.ok')) : Alert.error(t('Common.no'))
    }
    id && fetchData()
  }

  return (
    <div>

      {
        order &&
          <Panel>
            <h3>Order id: {id}</h3>

            <div className='div'>
              <p>Created at: {order.created_at}</p>
              <p>Canceled at: {order.is_canceled ? 'yes' : 'no'}</p>
              <p>Completed at: {order.is_done ? 'yes' : 'no'}</p>
              <p>Area: {order.area} km²</p>
              <p>Price: ${order.price_usd} USD</p>
              {/* <p>Created by: Gulnaz</p>
            <p>Responsible: Manager</p> */}
            </div>

            <br />
            <ButtonGroup>
              {/* <Button appearance='primary'>Show on Map</Button>
        <Button>Edit</Button> */}
              <Button appearance='primary' onClick={handleConfirmation}>Confirm</Button>
              {/* <Button appearance='subtle'>Cancel</Button> */}
            </ButtonGroup>

          </Panel>
      }
      {/* <Panel>
        <h4>TODO: Adding</h4>
        <ol>
          <li> map </li>
          <li> Show on map button functions </li>
          <li> edit button functions </li>
          <li> cancel button functions </li>
          <li> checking for login </li>
          <li> checking for non numerical id </li>

        </ol>

      </Panel> */}
    </div>
  )
}

export default CRMDetails
