/* eslint-disable react/no-unknown-property */
import React, { useState, useContext, useEffect } from 'react'
import { HOST_URL, SCENE_SETTINGS } from '../../../constants/constants'
import { FlexboxGrid, List, Alert, Loader } from 'rsuite'
import API from '../../../api'
import { SatImgContext } from '../../../contexts/DataProviderContext'
import { useTranslation } from 'react-i18next'
import ItemDetailsDrawer from '../../itemdetails/ItemDetailsDrawer'
import { BrowserRouter, Link } from 'react-router-dom'
import { NavigationContext } from '../../../contexts/NavigationContext'
import BuyNowModal from '../cart/BuyNowModal'
import useModal from '../../modal/useModal'

const SearchResultsList = ({ data, dataKey, setModalShow, ...props }) => {
  const { t } = useTranslation()
  const { dataTiles, activeTile, setActiveTile, chosenTiles, setChosenTiles, getDownloadLink } = useContext(SatImgContext)
  const { setNavItemId } = useContext(NavigationContext)
  const [isActive, setActive] = useState(false)
  const [isDrawn, setIsDrawn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chosenItems, setChosenItems] = useState(chosenTiles || [])
  const [sceneURL, setSceneURL] = useState('')
  const { isShowing, toggle } = useModal()

  const handleBuyClick = () => {
    if (sessionStorage.token || localStorage.token) {
      toggle()
    } else {
      setModalShow(true)
    }
  }

  const handleRowClick = data => {
    if ((activeTile?.id === data.id) && (!isActive)) {
      setActive(false)
    } else {
      setActive(true)
      setActiveTile(data, isActive)
    }
  }
  const handleDetailsClick = () => {
    setActive(true)
    setIsDrawn(true)
  }
  const handleAddtoCartClick = (item, hideFunc) => {
    // TODO: fetch before sending
    const params = {
      area_id: item.id || 0,
      application_theme: SCENE_SETTINGS.PURPOSE[0].value,
      acquisition_mode: SCENE_SETTINGS.MODES[0].value,
      processing_level: SCENE_SETTINGS.LEVELS[1].value,
      only_calculate: false, // to put in cart
      wishes: '-'
    }

    const fetchData = async (params) => {
      const result = await API.cart.cart_archive(params)

      setChosenItems(() => [...chosenItems, {
        ...item,
        area: result.area,
        price_kzt: result.price_kzt,
        price_usd: result.price_usd
      }])
      if (hideFunc) {
        setNavItemId(4)
        hideFunc()
      }
    }
    if (chosenTiles !== []) {
      // console.log('Allowed to fetch')
      fetchData(params)
    }
  }

  useEffect(() => {
    const removeDuplicatesFromArrayByProperty = (arr, prop) => arr.reduce((accumulator, currentValue) => {
      if (!accumulator.find(obj => obj[prop] === currentValue[prop])) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, [])

    setChosenTiles(removeDuplicatesFromArrayByProperty(chosenItems, 'id'))
  }, [chosenItems, setChosenItems])

  // useEffect(() => {
  //   console.log(chosenTiles)
  // }, [chosenTiles, setChosenTiles])

  const handleDownloadClick = (id) => {
    setLoading(true)
    const res = getDownloadLink(id, setLoading)
    setSceneURL(res.url)
  }

  // useEffect(() => {
  //   // Usage
  //   const jsonBlob = new Blob(['{"name": "test"}'])
  //   downloadBlob(jsonBlob, sceneURL)
  // }, [setSceneURL])

  const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    link.href = blobUrl
    link.download = name

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )

    // Remove link from body
    document.body.removeChild(link)
  }

  return (
    <>
      <List hover>
        {dataTiles?.map((item, rowIndex) => (
          <List.Item key={item.id} id = {item.id} index={rowIndex + 1} data={item} onClick={() => handleRowClick(item)} style={((item.id === activeTile.id)) ? { background: '#1b3c5c' } : { color: '' }}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={6} className='centered h-60'>
                <img src={HOST_URL + item.quicklook_url} alt='preview' className='w-64 preview' />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={18} className='centered h-60'>
                <div className='title f-w-600'>
                  <p style={((item.id === activeTile.id)) ? { color: '#E3AF34' } : { color: '' }}>{item.id ? item.id : '-'}</p>
                </div>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={12}>
                    <div className='slim-text'>
                      {t('Scene.satellite')}: <span className='f-w-600 color-highlight'>{item.satellite}</span><br />
                      {t('Common.date')}:
                      <span className='f-w-600'>
                        {/* {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(item.meta_date)} */}
                        {/* {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(item.meta_date)} */}
                        {item.meta_date}
                      </span><br />
                      {/* {t('Common.time')}: <span className='f-w-600'>{new Intl.DateTimeFormat('en-US', { timeStyle: 'long' }).format(item.meta_date)}</span><br /> */}
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <div className='slim-text'>
                      {t('Scene.clouds')}: <span className='f-w-600'>{item.cloud_coverage ? item.cloud_coverage : '-'} </span>%<br />
                      {t('Scene.pitch')}: <span className='f-w-600'>{item.pitch ? item.pitch.toFixed(2) : '-'}</span><br />
                      {t('Scene.roll')}: <span className='f-w-600'>{item.roll ? item.roll.toFixed(2) : '-'}</span>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <div className='slim-text'>
                      <div>
                        <a href='#' onClick={toggle}>{t('Common.details')}</a><br />
                        <a href='#' onClick={() => handleAddtoCartClick(item)}>{t('Common.to_cart')}</a><br />
                        {/* <a href='#' show={isShowing} onClick={toggle} className='color-highlight'>{t('Common.buy_now')}</a> */}
                      </div>

                      {/* <div>
                        {
                          (sceneURL === '')
                            ? <a href='#' onClick={() => handleDownloadClick(item.id)}>{t('Common.download')}</a>
                            : <a href={sceneURL} className='color-highlight' download>{t('Common.download')}</a>
                        }
                      </div> */}
                      {/* {
                        (rowIndex < 1)
                          ? (
                            <div>
                              <BrowserRouter>
                                <Link to={activeTile.download_url} target='_blank' download>{t('Common.download')} </Link>
                              </BrowserRouter>
                            </div>
                            )
                          : (
                            <div>
                              <a href='#' onClick={() => handleAddtoCardClick(item)}>{t('Common.to_cart')}</a><br />
                              <a href='#' show={isShowing} onClick={toggle} className='color-highlight'>{t('Common.buy_now')}</a>
                            </div>
                            )
                      } */}
                    </div>
                  </FlexboxGrid.Item>
                </FlexboxGrid>

              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
      <ItemDetailsDrawer drawn={isDrawn} setIsDrawn={setIsDrawn} activeTile={activeTile} sceneURL />
      <BuyNowModal
        show={isShowing}
        hide={toggle}
        item={activeTile}
        addToCart={handleAddtoCartClick}
      />
      {loading && <Loader inverse size='md' center className='z-600' />}

    </>
  )
}

export default SearchResultsList
