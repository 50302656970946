import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { IconButton, ButtonGroup, Icon, Panel, FlexboxGrid } from 'rsuite'
import { NavigationContext } from '../../../contexts/NavigationContext'

const ActionSection = () => {
  const { t } = useTranslation()
  const { setNavItemId } = React.useContext(NavigationContext)

  // const iconButton = ({ icon, placement, navItemId, text }) => {
  //   return (
  //     <IconButton
  //       icon={<Icon icon={icon} />}
  //       placement={placement}
  //       onClick={() => setNavItemId(navItemId)}
  //       appearance='primary'
  //     >
  //       {text}
  //     </IconButton>
  //   )
  // }

  return (
    <Panel bordered>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <Trans
            i18nKey='Dashboard.Action.info'
            defaults='Start your <bold>search</bold> or <bold>order</bold> new acquisition by choosing your options'
            components={{ bold: <strong /> }}
          />

        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <ButtonGroup vertical size='xs'>
            <IconButton
              icon={<Icon icon='search' />}
              placement='left'
              onClick={() => setNavItemId(2)}
              appearance='ghost'
            >
              {t('Dashboard.Action.search')}
            </IconButton>
            <IconButton
              icon={<Icon icon='file-text-o' />}
              placement='left'
              onClick={() => setNavItemId(3)}
              appearance='ghost'
            >
              {t('Dashboard.Action.new')}
            </IconButton>
          </ButtonGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  )
}

export default ActionSection
