import React from 'react'
import { Table } from 'rsuite'

const PriceCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table

  return (
    <Cell {...props}>
      <div>
        <label>
          <small className='color-highlight'>
            {new Intl.NumberFormat('kz-KZ', { style: 'currency', currency: 'KZT', minimumFractionDigits: 2 }).format(rowData.price_kzt)}
          </small>
          <br />
          <small>
            {
              new Intl.NumberFormat('en-En',
                { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }
              ).format(rowData.price_usd)
            }
          </small>
        </label>
      </div>
    </Cell>
  )
}

export default PriceCell
