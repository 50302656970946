import { React, useState } from 'react'
import { Icon, Form, Button, FormGroup, FormControl } from 'rsuite'
import circle from '@turf/circle'

const GeoPoint = ({ clearMap, isVisible, onSubmit, addToMap }) => {
  const [formData, setFormData] = useState({
    lon: '5.129514829961408',
    lat: '59.526901422612866'
  })
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const handleSearch = () => {
    const circleCenter = [formData.lon, formData.lat]
    const radius = 50
    const options = {
      steps: 12,
      units: 'meters'
    }
    const polygon = circle(circleCenter, radius, options)
    addToMap(polygon)
  }
  const divClassName = isVisible ? 'geoPointVisible' : 'hidden'
  return (
    <div className={divClassName}>
      <Form layout='inline'>
        <FormGroup>
          <FormControl name='lon' value={formData.lon} onChange={ (value) => handleInputChange('lon', value) } />
        </FormGroup>
        <FormGroup>
          <FormControl name='lat' value={formData.lat} onChange={ (value) => handleInputChange('lat', value) } />
        </FormGroup>
        <FormGroup>
          <Button appearance='primary' onClick={handleSearch}><Icon icon={'search'}/></Button>
        </FormGroup>
      </Form>
    </div>
  )
}

export default GeoPoint
