import { HOST_URL } from '../../../constants/constants'
import { Table } from 'rsuite'

const ImageCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table

  const handleAction = () => {
    // setActiveTile(rowData)
  }

  return (
    <Cell {...props} className='p-0'>
      <div className='cell-img-preview'>
        <img src={HOST_URL + rowData[dataKey]} width='42' style={{ maxWidth: '44' }} onClick={handleAction} alt='preview' />
      </div>
    </Cell>
  )
}

export default ImageCell
