import { ORDERS_GOTTEN, ORDER_GOTTEN, ORDER_CANCELLED } from '../types'
export default (state = {}, action = {}) => {
  switch (action.type) {
    case ORDERS_GOTTEN:
      return action.orders
    case ORDER_GOTTEN:
      return state
    case ORDER_CANCELLED:
      return state
    default:
      return state
  }
}
