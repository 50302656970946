import React from 'react'
import { Sidenav } from 'rsuite'
import NewOrderRequestPanel from './neworderelements/NewOrderRequestPanel'
import useModal from '../modal/useModal'

const NewOrderSidenav = (props) => {
  const { isShowing, toggle } = useModal()

  return (
    <Sidenav appearance='subtle'>
      <Sidenav.Body className='p-15'>
        <NewOrderRequestPanel height={props.height} />
      </Sidenav.Body>
    </Sidenav>
  )
}

export default NewOrderSidenav
