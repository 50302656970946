import React, { useState, useContext, useEffect } from 'react'
import { ButtonToolbar, ButtonGroup, Button, Panel, DateRangePicker, RangeSlider, Slider, DatePicker } from 'rsuite'
import moment from 'moment'
import { SearchOptionsContext } from '../../../contexts/SearchDetailsContext'
import { useTranslation, Trans } from 'react-i18next'

const SearchFilterSettings = (props) => {
  const { t, i18n } = useTranslation()
  const [active, setActive] = useState('KazEOSat-1')
  const { searchOptions, setSearchOptions } = useContext(SearchOptionsContext)
  const [cloudCoverage, setCloudCoverage] = useState([0, 100])
  const [dateRange, setDateRange] = useState([])

  const CustomButtonGroup = ({ appearance }) => (
    <ButtonToolbar>
      <ButtonGroup>
        <Button
          active={(active === 'KazEOSat-1')}
          size='xs'
          appearance={appearance}
          onClick={() => {
            setActive('KazEOSat-1')
            setSearchOptions({
              satellite: 'KazEOSat-1',
              date_from: searchOptions.date_from,
              date_to: searchOptions.date_to,
              geometry: searchOptions.geometry,
              cloud_coverage: searchOptions.cloudCoverage
            })
          }}
        >
          KazEOSat-1
        </Button>
        <Button
          active={(active === 'KazEOSat-2')}
          size='xs'
          appearance={appearance}
          onClick={() => {
            setActive('KazEOSat-2')
            setSearchOptions({
              satellite: 'KazEOSat-2',
              date_from: searchOptions.date_from,
              date_to: searchOptions.date_to,
              geometry: searchOptions.geometry,
              cloud_coverage: searchOptions.cloudCoverage
            })
          }}
        >
          KazEOSat-2
        </Button>
        <Button
          active={(active === 'KazSTSat')}
          size='xs'
          appearance={appearance}
          onClick={() => {
            setActive('KazSTSat')
            setSearchOptions({
              satellite: 'KazSTSat',
              date_from: searchOptions.date_from,
              date_to: searchOptions.date_to,
              geometry: searchOptions.geometry,
              cloud_coverage: searchOptions.cloudCoverage
            })
          }}
        >
          KazEOSat-2
        </Button>
        {/* <Button appearance={appearance}>Both</Button> */}
      </ButtonGroup>
    </ButtonToolbar>
  )
  useEffect(() => {
    console.log(dateRange)
  }, [dateRange])
  return (
    <Panel align='center' className='shrink'>
      {i18n.language !== 'kz' && t('Weather.from')}
      {/* <DatePicker
        showOneCalendar
        size='xs'
        placeholder={`${searchOptions.date_from}`}
        appearance='subtle'
        onChange={value => {
          setDateRange([moment(value).format('YYYY-MM-DD'), dateRange[1]])
        }}
        placement='bottomEnd'
      />
      {i18n.language === 'kz' && t('Weather.from')}
      {i18n.language !== 'kz' && t('Weather.to')}
      <DatePicker
        showOneCalendar
        size='xs'
        placeholder={`${searchOptions.date_to}`}
        appearance='subtle'
        onChange={value => {
          setDateRange([dateRange[0], moment(value).format('YYYY-MM-DD')])
        }}
        placement='bottomEnd'
      />
      {i18n.language === 'kz' && t('Weather.to')} */}
      {t('Search.daterange')}
      <DateRangePicker
        showOneCalendar
        size='xs'
        placeholder={`${searchOptions.date_from} ~ ${searchOptions.date_to}`}
        appearance='subtle'
        onChange={value => {
          setDateRange([moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')])
        }}
        placement='bottomEnd'
      />
      <Panel >
        <Trans
          defaults='Weather.cloud' // optional defaultValue
          values={{ cloudFrom: cloudCoverage[0], cloudTo: cloudCoverage[1] }}
        />
        <Panel>
          <Slider progress
            defaultValue={cloudCoverage[1]}
            onChange={v => {
              if (v !== cloudCoverage[1]) {
                setCloudCoverage([0, v])
              }
            }}
          />
        </Panel>
        <Button appearance='primary' onClick={() => {
          setSearchOptions({
            satellite: searchOptions.satellite,
            date_from: dateRange[0] ? dateRange[0] : searchOptions.date_from,
            date_to: dateRange[1] ? dateRange[1] : searchOptions.date_to,
            geometry: searchOptions.geometry,
            cloud_coverage: cloudCoverage[1]
          })
        }}>{t('Weather.accept')}</Button>
      </Panel>
      {/* <CustomButtonGroup appearance='subtle' /> */}
    </Panel>
  )
}

export default SearchFilterSettings
