import React from 'react'
import ReactDOM from 'react-dom'
import { Modal, Button, Icon } from 'rsuite'

const ErrorModal = ({ isShowing, hide, icon, title, message }) => isShowing
  ? ReactDOM.createPortal(
    <>
      <Modal
        show={isShowing}
        onHide={hide}
      >
        <Modal.Header>
          <Modal.Title><Icon icon='exclamation-triangle' className='attention-color' /> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Icon
            icon={icon}
            className='attention-color f-24'
          />
          {'  '}
          {message}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hide} appearance='primary'>
            Ok
          </Button>
          <Button onClick={hide} appearance='subtle'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>, document.body)
  : null

export default ErrorModal
