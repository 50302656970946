import { useEffect, useState, useContext } from 'react'
import API from '../../../api'
import ReactDOM from 'react-dom'
import { Modal, Button, FlexboxGrid, Panel, SelectPicker, Alert } from 'rsuite'
import { HOST_URL, SCENE_SETTINGS } from '../../../constants/constants'
import { useTranslation, Trans } from 'react-i18next'
import { NavigationContext } from '../../../contexts/NavigationContext'
import axios from 'axios'

const BuyNowModal = ({ show, hide, item, addToCart }) => {
  const { t } = useTranslation()
  const { setNavItemId } = useContext(NavigationContext)
  const [calculatedScene, setCalculatedScene] = useState({})
  const [hiddenCart, setHiddenCart] = useState(false)
  // const axios = require('axios')
  const params = {
    area_id: item.id || 0,
    application_theme: SCENE_SETTINGS.PURPOSE[0].value,
    acquisition_mode: SCENE_SETTINGS.MODES[0].value,
    processing_level: SCENE_SETTINGS.LEVELS[1].value,
    only_calculate: false, // To Put directly in cart
    wishes: '-'
  }

  useEffect(() => {
    const fetchData = async () => {
      if (params.area_id > 0) {
        const result = await API.cart.cart_archive(params)
        setCalculatedScene(() => { return result })
      }
    }
    fetchData()
  }, [item.id > 0])

  const handleBuyClick = () => {
    if (calculatedScene.success) {
      const fetchDataToOrder = async () => {
        // TODO: add to chosenTiles and to ordering?
        const resultingOrder = await API.cart.submit_cart()
        console.log(calculatedScene, resultingOrder)
        const img = await axios.get(`https://eo.gharysh.kz/api/quicklooks/${resultingOrder.order.images[0].code}_QL.jpeg`)
        // console.log(img)
        try {
          const user = JSON.parse(localStorage.user)
          delete axios.defaults.headers.common.Authorization
          axios.post('https://bitrix.gharysh.kz/rest/1653/ixzmgelctx8dxyyw/crm.deal.add', {
            fields: {
              ID: resultingOrder.order.id,
              TITLE: `Sunkarsat deal # ${resultingOrder.order.id}`,
              IS_NEW: 'Y',
              OPPORTUNITY: calculatedScene.price_kzt,
              DATE_MODIFY: calculatedScene.updated_at,
              // TYPE: 'Existing satellite shapshot',
              PRICE_USD: calculatedScene.price_usd,
              PRICE_KZT: calculatedScene.price_kzt,
              UPDATE_DATE: calculatedScene.updated_at,
              UF_CRM_1639291567400: user.id,
              UF_CRM_1639291607900: user.first_name + ' ' + user.last_name,
              UF_CRM_1639291655300: user.email,
              UF_CRM_1639291730200: user.company,
              UF_CRM_1639291742800: user.phone,
              UF_CRM_1639291771500: user.purpose,
              UF_CRM_1639291911500: calculatedScene.pricing.id,
              UF_CRM_1639291942700: calculatedScene.area + ' sq km',
              // UF_CRM_1639293703200: img.data,
              UF_CRM_1639293212700: `https://eo.gharysh.kz/api/quicklooks/${resultingOrder.order.images[0].code}_QL.jpeg`,
              UF_CRM_1639292035900: calculatedScene.pricing.satellite,
              // IMAGES: calculatedScene.images,
              // "COMPANY_ID": 3,
              // "CONTACT_ID": 3,
              // "OPENED": "Y",
              // "ASSIGNED_BY_ID": 1,
              CURRENCY_ID: 'KZT'
            }
          }, {
            headers: {
              'content-type': ['application/json']
            }
          }
          )
            .then((res) => res.data)
            .catch((err) => err.response.data)
        } catch {
          console.log('bitrix post failed')
        }
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`
        // setOrder(() => resultingOrder)
        // setChosenTiles(() => { return [] })

        Alert.success(t('Order.Confirmation.request_confirmation_message'))
        // TODO: why this is not closing?
        setNavItemId(() => { return 2 })
      }
      fetchDataToOrder()
    }
  }

  return show
    ? ReactDOM.createPortal(
      <>
        <Modal
          show={show}
          onHide={hide}
        >
          <Modal.Header>
            <Modal.Title>
              <h5><Trans i18nKey='Cart.scene_purchase_title' /> #{item.id}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <BuyNowModalBody item={item} params={params} /> */}
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12}>
                <img src={HOST_URL + item.quicklook_url} alt='preview' className='w-256' />
                <br />
                <br />
                <h6>
                  {calculatedScene.success && <p>{t('Common.area')} <b className='color-highlight'>{new Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(calculatedScene.area)}</b> km²</p>}
                </h6>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12}>
                {/* <h4>Scene Id: {item.id}</h4>
        <br /> */}
                {/* <p>To: {userInfo.first_name} {userInfo.last_name}, {userInfo.company}</p> */}
                <Panel bordered>
                  <small>
                    {t('Common.id')}: <span className='color-highlight'>{item.id ? item.id : '-'}</span><br />
                    {t('Scene.code')}: <small style={{ fontSize: '.6rem' }}>{item.code ? item.code : '-'} {item.parent_code ? item.parent_code : '-'}</small><br />
                    {t('Scene.satellite')}: <span className='color-highlight'>{item.satellite}</span><br />
                    {t('Common.date')}: <span className='color-highlight'>{item.meta_date}</span><br />
                    {t('Scene.clouds')}: <span style={{ fontWeight: 'bold' }} className='color-highlight'>{item.cloud_coverage ? item.cloud_coverage : '-'} %</span><br />
                    {t('Scene.pitch')}: <span className='color-highlight'>{item.pitch ? item.pitch.toFixed(2) : '-'}</span><br />
                    {t('Scene.roll')}: <span className='color-highlight'>{item.roll ? item.roll.toFixed(2) : '-'}</span><br />
                    {t('Scene.inc')}: <span className='color-highlight'>{item.incidence_angle ? item.incidence_angle.toFixed(2) : '-'}</span><br />
                    {t('Scene.sun_azimuth_angle')}: <span className='color-highlight'>{item.sun_azimuth_angle ? item.sun_azimuth_angle.toFixed(2) : '-'}</span><br />
                    {t('Scene.sun_elevation_angle')}: <span className='color-highlight'>{item.sun_elevation_angle ? item.sun_elevation_angle.toFixed(2) : '-'}</span><br />
                    {t('Scene.viewing_angle_across_track')}: <span className='color-highlight'>{item.viewing_angle_across_track ? item.viewing_angle_across_track.toFixed(2) : '-'}</span><br />
                    {t('Scene.viewing_angle_along_track')}: <span className='color-highlight'>{item.viewing_angle_along_track ? item.viewing_angle_along_track.toFixed(2) : '-'}</span><br />
                  </small>
                  {/* <p>{t('Order.application_mode')}</p>
          <RadioGroup name='radioList' inline defaultValue={applicationMode} onChange={setApplicationMode}>
            <Radio value='Mono'><small>Mono</small></Radio>
            <Radio value='Stereo'><small>Stereo</small></Radio>
          </RadioGroup> */}
                  {/* <p>
                    {t('Order.application_purpose')}
                    <SelectPicker
                      appearance='subtle'
                      data={SCENE_SETTINGS.PURPOSE}
                      searchable={false}
                      onChange={setApplicationPurpose}
                      placeholder={SCENE_SETTINGS.PURPOSE[0].label}
                      defaultValue={SCENE_SETTINGS.PURPOSE[0].value}
                      cleanable={false}
                    />
                  </p>
                  <p>
                    {t('Order.Processing.Level')}
                    <SelectPicker
                      appearance='subtle'
                      data={SCENE_SETTINGS.LEVELS}
                      searchable={false}
                      onChange={setProcessingLevel}
                      placeholder={SCENE_SETTINGS.LEVELS[0].label}
                      defaultValue={SCENE_SETTINGS.LEVELS[0].value}
                      cleanable={false}
                    />
                  </p>
                  <p>
                    {t('Order.Priority.desc')}
                    <SelectPicker
                      appearance='subtle'
                      data={SCENE_SETTINGS.PRIORITIES}
                      searchable={false}
                      onChange={setPrirority}
                      placeholder={SCENE_SETTINGS.PRIORITIES[0].label}
                      defaultValue={SCENE_SETTINGS.PRIORITIES[0].value}
                      cleanable={false}
                    />
                  </p> */}
                </Panel>
                <br />
                <div className='text-right'>
                  {calculatedScene.success &&
                    <div>
                      <h6>
                        {t('Common.price')}: <b className='color-highlight'>{new Intl.NumberFormat('kz-KZ', { style: 'currency', currency: 'KZT', minimumFractionDigits: 2 }).format(calculatedScene.price_kzt)}</b>
                        <br />
                        USD <span>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(calculatedScene.price_usd)}</span>
                      </h6>
                    </div>}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => { addToCart(item, hide) }} appearance='primary'>
              <Trans i18nKey='Common.pay_now' />
            </Button>
            <Button style={hiddenCart ? { display: 'none' } : { display: 'inline' }} onClick={() => { addToCart(item); setHiddenCart(true); Alert.success('Товар успешно добавлен в корзину!') }} appearance='primary'>
              <Trans i18nKey='Common.to_cart' />
            </Button>
            <Button onClick={hide} appearance='subtle'>
              <Trans i18nKey='Common.cancel' />
            </Button>
          </Modal.Footer>
        </Modal>
      </>, document.body)
    : null
}

export default BuyNowModal
