import { useTranslation } from 'react-i18next'
// const { t } = useTranslation()

// baseuRL
// const baseURL = ((window.location.hostname === 'localhost') || (window.location.hostname === '10.0.14.94')) ? 'http://eo.gharysh.kz' : window.location.origin
// export const HOST_URL = 'http://10.0.3.101:4001'
export const HOST_URL = 'https://eo.gharysh.kz'
export const API_PATH = '/api/v1'
// Aquistion demands
export const SCENE_SETTINGS = {
  LEVELS: [
    {
      label: 'L1',
      value: 'L1'
    },
    {
      label: 'L2',
      value: 'L2'
    },
    {
      label: 'L3',
      value: 'L3'
    },
    {
      label: 'L4',
      value: 'L4'
    },
    {
      label: 'L5',
      value: 'L5'
    }
  ],
  PRIORITIES: [
    {
      // label: 'standard'  || t('Common.Priority.standard'),
      label: 'standard / стандартты / стандартный',
      value: 'standard'
    },
    {
      label: 'high / жоғары / высокий',
      value: 'high'
    },
    {
      label: 'urgent / шұғыл / срочный',
      value: 'urgent'
    }
  ],
  PURPOSE: [
    {
      label: 'Change detection (default) / Өзгерістерді анықтау (әдепкі) / Обнаружение изменений (по умолчанию)',
      value: 'Change detection'
    },
    {
      label: 'Agriculture / Ауыл шаруашылығы / Агрокультура',
      value: 'Agriculture'
    },
    {
      label: 'Cartography / Картография',
      value: 'Cartography'
    },
    {
      label: 'Other / Другое / Басқа',
      value: 'Other'
    }
  ],
  MODES: [
    {
      label: 'mono',
      value: 'mono'
    },
    {
      label: 'stereo',
      value: 'stereo'
    }
  ],
  SATELLITES: [
    {
      label: 'high (KazEOSat-1)',
      value: 'KazEOSat-1'
    },
    {
      label: 'middle KazEOSat-2',
      value: 'KazEOSat-2'
    },
    {
      label: 'middle KazSTSat',
      value: 'KazSTSat'
    }
  ],
  RESOLUTION: [
    {
      label: 'high (KazEOSat-1)',
      value: 'HIGH'
    },
    {
      label: 'middle KazEOSat-2',
      value: 'MEDIUM'
    },
    {
      label: 'middle KazSTSat',
      value: 'MEDIUM'
    }
  ]
}
