import React from 'react'
import { Table } from 'rsuite'
import moment from 'moment'

const DateCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table

  return (
    <Cell {...props}>
      <div>
        <small>
          {`${moment(rowData.created_at).format('l')}`}
        </small>
      </div>
    </Cell>
  )
}

export default DateCell
