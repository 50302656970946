import React, { createContext, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'rsuite'
import API from '../api'
import ErrorModal from '../components/errors/ErrorModalTemplate'
import useModal from '../components/modal/useModal'
import { SearchOptionsContext } from './SearchDetailsContext'
import { NavigationContext } from '../contexts/NavigationContext'

export const SatImgContext = createContext()

const SatImgContextProvider = (props) => {
  const { setSearchOptions } = useContext(SearchOptionsContext)
  const { t } = useTranslation()
  const { navItemId } = useContext(NavigationContext)
  const [dataTiles, setDataTiles] = useState([])
  const [chosenTiles, setChosenTiles] = useState([])
  const [activeTile, setActiveTile] = useState({
    data: {},
    active: false
  })

  const { isShowing, toggle } = useModal()

  const updateSatelliteData = (data) => {
    setDataTiles(data)
  }

  const downloadBlob = (url, name) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    // const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    // link.href = blobUrl
    link.href = url
    link.download = name

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
    window.location.href = url
    // Remove link from body
    document.body.removeChild(link)
  }

  async function getDownloadLink (id, setLoading) {
    const reguestDownload = await API.scenes.download_link(id) // 33 sec
    // downloadBlob(reguestDownload.url, id)
    setLoading(() => false)

    if (!reguestDownload.success) {
      (reguestDownload.error === 'ImageNotFound') ? Alert.error(t('Errors.image_not_found')) : Alert.error(t('Errors.download_limit'))
    } else {
      const download = await API.scenes.download(id)
      downloadBlob(reguestDownload.url, id)
    }
  }

  async function fetchDataTiles (searchOptions, setIsLoading) {
    const demo = sessionStorage.getItem('demo') ? 'demo' : ''
    const resultReq1 = await API.scenes.get(searchOptions, demo)

    const resultReq2 = await API.scenes.get({
      satellite: (searchOptions.satellite === 'KazEOSat-2') ? 'KazEOSat-1' : 'KazEOSat-2',
      date_from: searchOptions.date_from,
      date_to: searchOptions.date_to,
      geometry: searchOptions.geometry,
      cloud_coverage: searchOptions.cloud_coverage
    }, demo)

    const resultReq3 = await API.scenes.get({
      satellite: 'KazSTSat',
      date_from: searchOptions.date_from,
      date_to: searchOptions.date_to,
      geometry: searchOptions.geometry,
      cloud_coverage: searchOptions.cloud_coverage
    }, demo)

    resultReq2?.satellite_images?.map(scene => {
      // scene.quicklook_url = (scene.satellite === 'KazEOSat-2') ? '/api' + scene.quicklook_url : scene.quicklook_url
      scene.geometry.coordinates = scene.geometry.coordinates.map(item => item.map(row => row.map(Number)))
    })

    const res = (resultReq1.satellite_images && resultReq2.satellite_images && resultReq3.satellite_images) && [...resultReq1.satellite_images, ...resultReq2.satellite_images, ...resultReq3.satellite_images]

    const tileResults = res?.filter(item => (
      ((item.satellite === 'KazEOSat-2') || (item.tile_service_url !== null)) && searchOptions.cloud_coverage && item.cloud_coverage < searchOptions.cloud_coverage
    ))
    if (tileResults?.length === 0 && navItemId === 2) toggle()

    setDataTiles(tileResults)
    setIsLoading(false)
  }
  // useEffect(() => {
  //   console.log(activeTile)
  // }, [activeTile])
  return (
    <SatImgContext.Provider value={{ dataTiles, setDataTiles, updateSatelliteData, fetchDataTiles, getDownloadLink, activeTile, setActiveTile, chosenTiles, setChosenTiles }}>
      {props.children}
      <ErrorModal
        isShowing={isShowing}
        hide={toggle}
        icon='exclamation-circle'
        title={t('Common.no_data')}
        message={t('Errors.area_empty')}
      />
    </SatImgContext.Provider>
  )
}

export default SatImgContextProvider
