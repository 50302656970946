import React, { createContext, useState } from 'react'
// import TestContext from './TestContext'

export const NavigationContext = createContext()

const NavigationContextProvider = (props) => {
  const [navItemId, setNavItemId] = useState(0)

  return (
    <NavigationContext.Provider
      value={{
        navItemId,
        setNavItemId
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  )
}

export default NavigationContextProvider
