
import React, { useContext } from 'react'
import { HOST_URL } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import { Drawer, Button } from 'rsuite'
import { SatImgContext } from '../../contexts/DataProviderContext'
import BuyNowModal from '../sidebarpanels/cart/BuyNowModal'
import useModal from '../modal/useModal'

const ItemDetailsDrawer = ({ drawn, setIsDrawn, sceneURL }) => {
  const { activeTile, downloadScene } = useContext(SatImgContext)
  const { t } = useTranslation()
  const { isShowing, toggle } = useModal()

  return (
    <Drawer
      size='xs'
      placement='right'
      backdrop={false}
      show={drawn}
      onHide={() => setIsDrawn(false)}
    >
      <Drawer.Header>
        <Drawer.Title><h3>{t('Common.details')}</h3></Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div>
          {t('Common.id')}: <span className='color-highlight'>{activeTile.id ? activeTile.id : '-'}</span><br />
          {t('Scene.code')}: <small style={{ fontSize: '.6rem' }}><br />{activeTile.code ? activeTile.code : '-'}<br />({activeTile.parent_code ? activeTile.parent_code : '-'})</small><br />
          {t('Scene.satellite')}: <span className='color-highlight'>{activeTile.satellite}</span><br />
          {t('Common.date')}: <span className='color-highlight'>{activeTile.meta_date}</span><br />
          {t('Scene.clouds')}: <span style={{ fontWeight: 'bold' }} className='color-highlight'>{activeTile.cloud_coverage ? activeTile.cloud_coverage : '-'} %</span><br />
          {t('Scene.pitch')}: <span className='color-highlight'>{activeTile.pitch ? activeTile.pitch.toFixed(2) : '-'}</span><br />
          {t('Scene.roll')}: <span className='color-highlight'>{activeTile.roll ? activeTile.roll.toFixed(2) : '-'}</span><br />
          {t('Scene.inc')}: <span className='color-highlight'>{activeTile.incidence_angle ? activeTile.incidence_angle.toFixed(2) : '-'}</span><br />
          {t('Scene.sun_azimuth_angle')}: <span className='color-highlight'>{activeTile.sun_azimuth_angle ? activeTile.sun_azimuth_angle.toFixed(2) : '-'}</span><br />
          {t('Scene.sun_elevation_angle')}: <span className='color-highlight'>{activeTile.sun_elevation_angle ? activeTile.sun_elevation_angle.toFixed(2) : '-'}</span><br />
          {t('Scene.viewing_angle_across_track')}: <span className='color-highlight'>{activeTile.viewing_angle_across_track ? activeTile.viewing_angle_across_track.toFixed(2) : '-'}</span><br />
          {t('Scene.viewing_angle_along_track')}: <span className='color-highlight'>{activeTile.viewing_angle_along_track ? activeTile.viewing_angle_along_track.toFixed(2) : '-'}</span><br />
        </div>
        <br />
        <div>
          <h5>{t('Common.preview')}</h5><br />
          <img src={HOST_URL + activeTile.quicklook_url} alt='preview' style={{ width: 360 }} />
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={toggle} show={isShowing} appearance='primary'>
          {t('Common.buy_now')}
        </Button>
        <Button onClick={() => setIsDrawn(false)} appearance='subtle'>
          {t('Common.cancel')}
        </Button>
      </Drawer.Footer>
      <BuyNowModal
        show={isShowing}
        hide={toggle}
        item={activeTile}
      />
    </Drawer>
  )
}

export default ItemDetailsDrawer
