// import axios from 'axios'
import { REQUEST_ERROR } from '../types'
import { logoutInvalidToken } from './auth'

export const errorOccurred = (data) => ({
  type: REQUEST_ERROR,
  error: data.error
})

export const errorShown = (data) => ({
  type: REQUEST_ERROR,
  error: {}
})

export const requestHasError = (data) => dispatch => {
  if (data.error) dispatch(errorOccurred(data))
  if (data.error && data.error.message === 'InvalidToken') dispatch(logoutInvalidToken())
}

export const errorWasShown = () => dispatch => {
  dispatch(errorShown())
}
