import React, { useContext, useRef, useEffect, useState } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { HOST_URL } from '../constants/constants'
import { SatImgContext } from '../contexts/DataProviderContext'
import { SearchOptionsContext } from '../contexts/SearchDetailsContext'
import { NavigationContext } from '../contexts/NavigationContext'
import { Loader, Alert, Button, ButtonGroup, Icon } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import UploadKML from '../imports/UploadKML'
import GeoPoint from '../imports/GeoPoint'
import Cadastr from '../imports/Cadastr'
import { add } from 'date-fns'

const Map = () => {
  const { t } = useTranslation()

  const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder, setShareTile } = useContext(SearchOptionsContext)
  const { navItemId, setNavItemId } = useContext(NavigationContext)

  const [searchLayer, setSearchLayer] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup

  const [activeButton, setActiveButton] = useState('addressButton')
  const [lastDrawing, setLastDrawing] = useState(null)

  const [mapControlTools, setMapControlTools] = useState(null)
  const [isGeoPointVisible, setGeoPointVisibility] = useState(false)
  const [isCadastrVisible, setCadastrVisibility] = useState(false)
  const [isUploadVisible, setUploadVisibility] = useState(false)

  const handleButtonClick = (key) => {
    setActiveButton(key)
  }

  const showGeoPointControl = () => {
    mapControlTools.removeSearchControl()
    setGeoPointVisibility(true)
    setCadastrVisibility(false)
    setUploadVisibility(false)
    handleButtonClick('geoPointButton')
  }

  const showAddressControl = () => {
    mapControlTools.addSearchControl()
    setGeoPointVisibility(false)
    setCadastrVisibility(false)
    setUploadVisibility(false)
    handleButtonClick('addressButton')
  }

  const showCadastrControl = () => {
    mapControlTools.removeSearchControl()
    setGeoPointVisibility(false)
    setCadastrVisibility(true)
    setUploadVisibility(false)
    handleButtonClick('cadastrButton')
  }

  const showUploadControl = () => {
    mapControlTools.removeSearchControl()
    setGeoPointVisibility(false)
    setCadastrVisibility(false)
    setUploadVisibility(true)
    handleButtonClick('uploadButton')
  }

  const moveToGeoPoint = (formData) => {
    mapRef?.current?.panTo(formData)
  }

  const handlePolygonClick = (e) => {
    const point = turf.point([e.latlng.lng, e.latlng.lat])
    dataTiles?.forEach(scene => {
      if (turf.booleanPointInPolygon(point, scene.geometry)) {
        setActiveTile(scene, true)
        console.log(scene.id)
        document.getElementById(scene.id).scrollIntoView()
      }
    })
  }

  const addToMap = (feature) => {
    clearLayers()
    if (mapRef?.current) {
      const jsonLayer = L.geoJSON(feature)
      jsonLayer.addTo(mapRef?.current)
      jsonLayer.bringToFront()
      mapRef?.current.flyToBounds(jsonLayer.getBounds(), { duration: 3 })
      setSearchLayer(() => feature)
    }
  }

  // TODO: remove if not used in production = map cleaning - alternative method
  // function removeLayers () {
  //   if (mapRef?.current) {
  //     if (mapRef.current.hasLayer(drawnLayerRef.current)) {
  //       mapRef.current.removeLayer(drawnLayerRef.current)
  //     }
  //     if (mapRef.current.hasLayer(tileLayerRef.current)) {
  //       mapRef.current.removeLayer(tileLayerRef.current)
  //     }
  //   }
  // }

  function clearLayers () {
    if (mapRef?.current && mapRef?.current !== null) {
      if (searchGeometryRef.current !== null && mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (drawnLayerRef.current !== null && mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      if (tileLayerRef.current !== null && mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }

  useEffect(() => {
    if (!newOrder) {
      if (!(searchLayer && Object.keys(searchLayer).length === 0 && searchLayer.constructor === Object)) {
        // fetchData()
        setIsLoading(true)

        fetchDataTiles(searchOptions, setIsLoading)
      }
    }
  }, [searchOptions])

  /** map initialization */
  useEffect(() => {
    try {
      const map = L.map('map', {
        center: [51.15178610143037, 75.43212890625],
        zoom: 5,
        minZoom: 1,
        zoomControl: false
      })
      const tileLayer = L.tileLayer('https://c.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=6170aad10dfd42a38d4d8c709a536f38', {
        maxZoom: 18,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
      })
      tileLayer.setZIndex(-1)
      tileLayer.addTo(map)

      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      const mapControlTools = MapControls(mapRef.current, t)
      setMapControlTools(mapControlTools)
      mapRef.current.invalidateSize(9)
    } catch (e) {
      console.log(e)
    }
  }, [])

  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    clearLayers()
    const layer = e.layer
    const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
    if (area <= MAX_AREA) {
      setShareTile(() => layer.toGeoJSON())
      setSearchLayer(() => layer.toGeoJSON())
    } else {
      Alert.info(t('Search.big_area_restriction'), 5000)
    }
    searchGeometryRef.current.addLayer(layer)
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  useEffect(() => {
    setSearchOptions({
      satellite: searchOptions.satellite,
      date_from: searchOptions.date_from,
      date_to: searchOptions.date_to,
      geometry: searchLayer?.geometry,
      cloud_coverage: searchOptions.cloud_coverage
    })
  }, [searchLayer])
  /** Draw footprints every time satellite data updates */
  useEffect(() => {
    // Cleaning the map before re-draw, except searching Geometry
    if (mapRef?.current) {
      if (drawnLayerRef.current !== null && mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      if (tileLayerRef.current !== null && mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
    const drawnItemsGroup = L.featureGroup()
    if (dataTiles?.length > 0) setNavItemId(2)

    dataTiles?.map(tile => {
      const layer = L.geoJSON(tile.geometry)
      layer.layerID = tile.id
      layer.setStyle(footprintOptions)
      layer.addTo(drawnItemsGroup)
    })
    drawnItemsGroup.on('click', handlePolygonClick).addTo(mapRef.current)
    drawnItemsGroup.bringToFront()
    drawnLayerRef.current = drawnItemsGroup
  }, [dataTiles])

  /** Adding Img Layer */
  useEffect(() => {
    if (mapRef.current) {
      if (tileLayerRef.current !== null && mapRef.current.hasLayer(tileLayerRef.current)) {
        mapRef.current.removeLayer(tileLayerRef.current)
      }
    }
    console.log(activeTile)
    const tileGroup = L.featureGroup()
    if (dataTiles?.length > 0 || activeTile.geometry) {
      const tempCo = []
      console.log(activeTile.geometry.coordinates)
      activeTile.geometry.coordinates.forEach(co => {
        co.forEach(co2 => {
          tempCo.push(co2.slice(0, 2).reverse())
        })
      })
      const corners = tempCo
      console.log(corners)
      if ((activeTile?.tile_service_url != null) && ((activeTile.source === 'metadataservice') || (activeTile.source === 'CENTRAL_NODE'))) {
        const tiles = L.tileLayer(
          HOST_URL + activeTile.tile_service_url + '/{z}/{x}/{y}.png', {
          // tms: (q.tileServiceType === "tms")? true : false,
            tms: activeTile?.tile_service_type !== 'xyz',
            // tms: true,
            bounds: L.latLngBounds(corners),
            opacity: 1,
            // updateWhenIdle: true, - do not increase performance much
            minZoom: mapRef.current.minZoom,
            maxZoom: mapRef.current.maxZoom,
            minNativeZoom: activeTile.min_zoom,
            maxNativeZoom: activeTile.max_zoom
          })
        tiles.addTo(tileGroup)
        tileLayerRef.current = tileGroup
        // tileLayerRef.current.addTo(drawnLayerRef.current)
        tileLayerRef.current.addTo(mapRef.current)
        paneRef.current.appendChild(tiles.getContainer())
        tiles.setZIndex(5)
      } else {
        const quicklook = L.distortableImageOverlay(
          HOST_URL + activeTile.quicklook_url,
          {
            corners: corners
          })
        quicklook.addTo(drawnLayerRef.current)
      }
      drawnLayerRef.current.addTo(mapRef.current)
      mapRef.current.fitBounds(corners, { paddingBottomRight: [400, 0] })
    }
  }, [activeTile])

  useEffect(() => {
    clearLayers()
    if (jsonLayers && !newOrder) {
      L.geoJSON(jsonLayers).addTo(searchGeometryRef.current)
      // const geoJsonFeatures = jsonLayers.getLayers().map(feature => feature.getCoords())
      // mapRef.current.fitBounds()
    } else if (newOrder) {
      setNewOrder(() => false)
      L.geoJSON(jsonLayers).addTo(searchGeometryRef.current)
    }
  }, [jsonLayers])

  return (
    <div id='map'>
      <div id='searchTabsContainer'>
        <ButtonGroup id='searchTabs'>
          <Button className='tabButton' onClick={ showAddressControl }
          color={activeButton === 'addressButton' ? 'blue' : ''}>По адресу</Button>
          <Button className='tabButton' onClick={ showCadastrControl }
          color={activeButton === 'cadastrButton' ? 'blue' : ''}>По ГЗК</Button>
          <Button className='tabButton' onClick={ showGeoPointControl }
          color={activeButton === 'geoPointButton' ? 'blue' : ''}>По координате</Button>
          <Button className='tabButton' onClick={ showUploadControl }
          color={activeButton === 'uploadButton' ? 'blue' : ''}><Icon icon={'upload'}/> Загрузить</Button>
        </ButtonGroup>
      </div>
      { <Cadastr isVisible={isCadastrVisible} addToMap={addToMap} />}
      { <UploadKML id='refreshButton' isVisible={isUploadVisible} />}
      { <GeoPoint id='geoPoint' isVisible={ isGeoPointVisible} addToMap={addToMap} onSubmit={ moveToGeoPoint }/>}
      {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
    </div>
  )
}

export default Map
