import { Navbar, Nav, Icon } from 'rsuite'

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar className='nav-toggle'>
      <Navbar.Body>
        <Nav>
          <Nav.Item onClick={onChange} className='text-center w-56'>
            <Icon icon={expand ? 'angle-right' : 'angle-left'} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  )
}

export default NavToggle
