import { Sidenav, Panel } from 'rsuite'
import ActionSection from './dashboardelements/ActionSection'
import ContactSection from './dashboardelements/ContactSection'
import HelpSection from './dashboardelements/HelpSection'
import HelpTipsSection from './dashboardelements/HelpTipsSection'
import NewsSection from './dashboardelements/NewsSection'
import OfferSection from './dashboardelements/OfferSection'
import UpdatesSection from './dashboardelements/UpdateSection'

const DashboardSidenav = () => {
  return (
    <Sidenav appearance='subtle'>
      <Sidenav.Body>
        <Panel>
          <br />
          <ActionSection />
          {/* <br /> */}
          {/* <NewsSection /> */}
          {/* <br />
          <HelpSection /> */}
          <br />
          <OfferSection />
          <br />
          <ContactSection />
          <br />
          {/* <UpdatesSection />
          <br /> */}
          {/* <HelpTipsSection /> */}
        </Panel>

      </Sidenav.Body>
    </Sidenav>

  )
}

export default DashboardSidenav
