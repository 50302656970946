import React, { useEffect, useState, useContext } from 'react'
import { SCENE_SETTINGS } from '../../../constants/constants'
import moment from 'moment'
import { SelectPicker, Panel, Modal, Button, DateRangePicker, DatePicker, RangeSlider, Input, Radio, RadioGroup, Alert } from 'rsuite'
import { useTranslation, Trans } from 'react-i18next'
import { SearchOptionsContext } from '../../../contexts/SearchDetailsContext'
import API from '../../../api'
import NewOrderConfirmingModal from './NewOrderConfirmingModal'
import useModal from '../../modal/useModal'

const NewOrderRequestPanel = (props) => {
  const { t, i18n } = useTranslation()
  const SCENE_SETTINGS = {
    LEVELS: [
      {
        label: 'L1',
        value: 'L1'
      },
      {
        label: 'L2',
        value: 'L2'
      },
      {
        label: 'L3',
        value: 'L3'
      },
      {
        label: 'L4',
        value: 'L4'
      },
      {
        label: 'L5',
        value: 'L5'
      }
    ],
    PRIORITIES: [
      {
        // label: 'standard'  || t('Common.Priority.standard'),
        label: t('consts.priorities.standard'),
        value: 'standard'
      },
      {
        label: t('consts.priorities.high'),
        value: 'high'
      },
      {
        label: t('consts.priorities.urgent'),
        value: 'urgent'
      }
    ],
    PURPOSE: [
      {
        label: t('consts.purpose.detection'),
        value: 'Change detection'
      },
      {
        label: t('consts.purpose.agri'),
        value: 'Agriculture'
      },
      {
        label: t('consts.purpose.carto'),
        value: 'Cartography'
      },
      {
        label: t('consts.purpose.other'),
        value: 'Other'
      }
    ],
    MODES: [
      {
        label: 'mono',
        value: 'mono'
      },
      {
        label: 'stereo',
        value: 'stereo'
      }
    ],
    SATELLITES: [
      {
        label: 'high (KazEOSat-1)',
        value: 'KazEOSat-1'
      },
      {
        label: 'middle KazEOSat-2',
        value: 'KazEOSat-2'
      },
      {
        label: 'middle KazSTSat',
        value: 'KazSTSat'
      }
    ],
    RESOLUTION: [
      {
        label: 'high (KazEOSat-1)',
        value: 'HIGH'
      },
      {
        label: 'middle KazEOSat-2',
        value: 'MEDIUM'
      },
      {
        label: 'middle KazSTSat',
        value: 'MEDIUM'
      }
    ]
  }
  const { searchOptions, setSearchOptions, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const { isShowing, toggle } = useModal()

  const [isRequested, setIsRequested] = useState(false)
  const [cloudCoverage, setCloudCoverage] = useState([0, 100])
  const [pitch, setPitch] = useState([-15, 15])
  const [roll, setRoll] = useState([-35, 35])
  const [yaw, setYaw] = useState([0, 360])
  const [purpose, setPurpose] = useState(SCENE_SETTINGS.PURPOSE[0].value)
  const [resolution, setResolution] = useState(SCENE_SETTINGS.RESOLUTION[0].value)
  const [preorderingData, setPreorderingData] = useState({})
  const [dateRange, setDateRange] = useState([
    moment(new Date()).format('YYYY-MM-DD'),
    moment(new Date()).add(1, 'months').format('YYYY-MM-DD')
  ])
  const [processingLevel, setProcessingLevel] = useState(SCENE_SETTINGS.LEVELS[0].value)
  const [priority, setPrirority] = useState(SCENE_SETTINGS.PRIORITIES[0].value)
  const [calculateOnly, setCalculateOnly] = useState(false)
  const [isExpress, setIsExpress] = useState(false)
  const [geometryLayer, setGeometryLayer] = useState({
    type: 'Feature',
    properties: {},
    geometry: null
  })

  const [orderParams, setOrderParams] = useState({
    address: '-', // "address":"KazEOSat-1",
    type: 'geojson',
    geojson: geometryLayer,
    application_theme: purpose, // "application_theme":"agriculture",
    acquisition_mode: SCENE_SETTINGS.MODES[0].label, // "acquisition_mode":"mono",
    roll: 0, // TODO: accepts only 1 value sinstead of range
    pitch: 15, // TODO: accepts only 1 value sinstead of range
    yaw: 1, // TODO: accepts only 1 value sinstead of range
    cloud_coverage: cloudCoverage.values[1], // "cloud_coverage":"10",
    periods: dateRange, // "periods": [{"start":"2021-03-01", "end":"2021-03-31"}],
    resolution: resolution, // "resolution":"MEDIUM",
    processing_level: processingLevel, // "processing_level":"L1",
    wishes: '-', // "wishes": "Shoot me",
    priority: priority, // "priority":"standard",
    only_calculate: calculateOnly, // "only_calculate": "true",
    is_express: 'false' // "is_express": "false"
  })
  const toggleButton = () => {
    if (searchOptions.geometry) {
      toggle()
    } else {
      Alert.error(t('Errors.select_area'))
    }
  }
  useEffect(() => {
    setNewOrder(() => { return true })
    // setSearchOptions(() => { return {} })
  }, [])
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await API.cart.cart_new(orderRequest)
  //     setPreorderingData(result)
  //   }
  //   fetchData()
  // }, [orderRequest])

  useEffect(() => {
    // TODO: rolls, pitches, yaw and clouds accepts only max value (by API requirements), which should accept range later on
    setOrderParams(() => {
      return {
        address: resolution,
        type: 'geojson',
        geojson: geometryLayer,
        application_theme: purpose,
        acquisition_mode: 'mono',
        roll: roll[1],
        pitch: pitch[1],
        yaw: yaw[1],
        cloud_coverage: cloudCoverage[1],
        periods: [{ start: dateRange[0], end: dateRange[1] }],
        resolution: resolution,
        wishes: '',
        processing_level: processingLevel,
        priority: priority,
        only_calculate: calculateOnly // false to add cart
      }
    })
    console.log(dateRange)
  }, [resolution, processingLevel, purpose, dateRange, geometryLayer, priority, cloudCoverage, yaw, roll, pitch, calculateOnly])

  useEffect(() => {
    setGeometryLayer(() => {
      return {
        type: 'Feature',
        properties: {},
        geometry: searchOptions.geometry
      }
    })
  }, [searchOptions, newOrder])

  useEffect(() => {
    setIsRequested(() => { return !!(toggle) })
  }, [toggle])

  return (
    <Panel bodyFill id='third-step'>
      <h5 className='p-15'>{t('Order.title')}</h5>
        <span>
        <Panel bordered>
          <label> {t('Order.application_purpose')}</label>
          <SelectPicker
            appearance='subtle'
            data={SCENE_SETTINGS.PURPOSE}
            searchable={false}
            onChange={setPurpose}
            placeholder={SCENE_SETTINGS.PURPOSE[0].label}
            defaultValue={SCENE_SETTINGS.PURPOSE[0].value}
            cleanable={false}
          />
          <br />
          <label>{t('Order.choose_resolution')} </label>
          <RadioGroup name='radioList' inline defaultValue={resolution} onChange={setResolution}>
            <Radio value='HIGH'><small>{t('Order.Satellites.high')} (KazEOSat-1)</small></Radio>
            <Radio value='MEDIUM'><small>{t('Order.Satellites.middle')} (KazEOSat-2 & KazSTSat)</small></Radio>
          </RadioGroup>
          <label>{t('Order.Processing.Level')}</label>
          <SelectPicker
            appearance='subtle'
            data={SCENE_SETTINGS.LEVELS}
            searchable={false}
            onChange={setProcessingLevel}
            placeholder={SCENE_SETTINGS.LEVELS[0].label}
            defaultValue={SCENE_SETTINGS.LEVELS[0].value}
            cleanable={false}
            style={{ width: 60 }}
          />
          <br />
          <label>{t('Order.Priority.desc')}</label>
          <SelectPicker
            appearance='subtle'
            data={SCENE_SETTINGS.PRIORITIES}
            searchable={false}
            onChange={setPrirority}
            placeholder={SCENE_SETTINGS.PRIORITIES[0].label}
            defaultValue={SCENE_SETTINGS.PRIORITIES[0].value}
            cleanable={false}
            style={{ width: 130 }}
          />
          </Panel>
          <Panel bordered>
          <label>{t('Order.Dates.desc')}</label>
          <br />
          {i18n.language !== 'kz' && t('Weather.from')}
      <DatePicker
        size='xs'
        placeholder={moment(new Date()).format('YYYY-MM-DD') || `${searchOptions.date_from}`}
        appearance='subtle'
        onChange={value => {
          setDateRange([moment(value).format('YYYY-MM-DD'), dateRange[1]])
        }}
        placement='bottomStart'
      />
            {i18n.language === 'kz' && t('Weather.from')}
      {i18n.language !== 'kz' && t('Weather.to')}
      <DatePicker
        size='xs'
        placeholder={moment(new Date()).add('months', 1).format('YYYY-MM-DD') || `${searchOptions.date_to}`}
        appearance='subtle'
        onChange={value => {
          setDateRange([dateRange[0], moment(value).format('YYYY-MM-DD')])
        }}
        placement='bottomEnd'
      />
      {i18n.language === 'kz' && t('Weather.to')}
      <br />
          <small>
            <span className='padding-15 '> {t('Order.reoccuring')} </span>
            <RadioGroup className='padding-15' name='radioList' inline defaultValue='No'>
              <Radio value='Yes'>{t('Common.yes')}</Radio>
              <Radio value='No'>{t('Common.no')}</Radio>
            </RadioGroup>
          </small>
          </Panel>
        </span>
        <span>
          <Panel bordered>
            <label>{t('Order.cloud_coverage')} {t('Common.from')} ({cloudCoverage[0]}%) {t('Common.to')} ({cloudCoverage[1]}%)
              <RangeSlider
                className='m-10'
                defaultValue={cloudCoverage}
                onChange={v => {
                  setCloudCoverage([0, v[1]])
                }}
              />
            </label>
            <small>{t('Order.roll')} {t('Common.from')} ({roll[0]}°) {t('Common.to')} ({roll[1]}°)
              <RangeSlider
                className='m-10'
                defaultValue={roll} min={-90} step={1} max={90}
                onChange={v => {
                  setRoll(v)
                }}
              />
            </small>
            <small>{t('Order.pitch')} {t('Common.from')} ({pitch[0]}°) {t('Common.to')} ({pitch[1]}°)
              <RangeSlider
                className='m-10'
                defaultValue={pitch} min={-90} step={1} max={90}
                onChange={v => { setPitch(v) }}
              />
            </small>
            <small>{t('Order.yaw')} {t('Common.from')} ({yaw[0]}°) {t('Common.to')} ({yaw[1]}°)
              <RangeSlider
                className='m-5'
                defaultValue={yaw} min={0} step={1} max={360}
                onChange={v => {
                  setYaw(v)
                }}
              />
            </small>
          </Panel>

          <Input
            componentClass='textarea'
            rows={2}
            style={{ width: '100%', resize: 'none' }}
            placeholder={t('Common.additional_comments')}
          />
        </span>
        <div className='text-center p-15' height={props.height / 10}>
          <Button appearance='primary' onClick={toggleButton}>{t('Order.create_button')}</Button>
          <NewOrderConfirmingModal show={isShowing} hide={toggle} order={orderParams} isRequested={isRequested} />
        </div>
      </Panel>
  )
}

export default NewOrderRequestPanel
