import React from 'react'
import { Table } from 'rsuite'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const NameCell = ({ rowData, dataKey, ...props }) => {
  const { t } = useTranslation()
  const { Cell } = Table

  return (
    <Cell {...props} className='p-0'>
      <div>
        <span style={((rowData.id === props.activeTileID) && (props.isActive)) ? { color: '#E3AF34' } : { color: '' }}>
          {rowData.id ? rowData.id : '-'}
        </span>
        <div className='slim-text'>
          {/* {t('Scene.satellite')}: <span className='f-w-600 color-highlight'>{rowData.satellite}</span><br /> */}
          {t('Common.date')}:
          <span className='f-w-600'>
            {/* {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(item.meta_date)} */}
            {/* {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(item.meta_date)} */}
            {rowData.meta_date}
          </span><br />
          {/* {t('Common.time')}: <span className='f-w-600'>{new Intl.DateTimeFormat('en-US', { timeStyle: 'long' }).format(item.meta_date)}</span><br /> */}
        </div>
      </div>
    </Cell>
  )
}

export default NameCell
