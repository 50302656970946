import React, { useState, useContext } from 'react'
import { Avatar, Icon, FlexboxGrid, Button, Dropdown, IconButton, Loader } from 'rsuite'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import { logout } from '../../../actions/auth'
import * as actions from '../../../actions/auth'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import flags from 'country-flag-icons/react/1x1'
import { useEffect } from 'react/cjs/react.development'
import API from '../../../api'

const HeaderPanel = (props) => {
  const axios = require('axios')

  const { t, i18n } = useTranslation()
  const { heightHeaderTop } = props
  const { user } = props
  const { navItemId } = useContext(NavigationContext)
  const [loading, setLoading] = useState(false)
  const [lang, setLang] = useState('en')

  const currentSection =
    {
      0: 'Dashboard',
      1: 'History',
      2: 'Seacrh',
      3: 'Request',
      4: 'Settings'
    }[navItemId]

  const handleClick = (lang) => {
    i18next.changeLanguage(lang)
    setLang(lang)
  }
  const handleLogOut = () => {
    setLoading(true)

    delete axios.defaults.headers.common.Authorization
    if (sessionStorage.getItem('token') !== null) sessionStorage.removeItem('token')
    if (sessionStorage.getItem('user') !== null) sessionStorage.removeItem('user')
    if (localStorage.getItem('token') !== null) localStorage.removeItem('token')

    window.location.href = '/'
    setLoading(false)
  }

  return (
    <div className='top-user-info' style={{ height: 56 }}>

      <FlexboxGrid>
      {localStorage.getItem('token') &&
        <FlexboxGrid.Item colspan={3}>
         <Avatar circle>
             <Icon icon='user' />
            {/* <IconButton icon={<Icon icon='shopping-cart' />} /> */}
          </Avatar>
        </FlexboxGrid.Item>
}
        {localStorage.getItem('token') &&
        <FlexboxGrid.Item colspan={15}>

          <p className='p-10'>
            <small>
              {(user.first_name) ? user.first_name : ''} {(user.last_name) ? user.last_name : ''}
            </small>
          </p>
        </FlexboxGrid.Item>
}
        {localStorage.getItem('token') &&
        <FlexboxGrid.Item colspan={4}>
          <Button className='m-5' appearance='ghost' size='xs' onClick={handleLogOut}>{t('Auth.Log_out')}</Button>
        </FlexboxGrid.Item>
}
<FlexboxGrid.Item colspan={2}>
          {/* <Dropdown title='EN' icon={<Icon icon='language' appearance='ghost' size='xs' />}> */}
          <Dropdown
            className='m-5'
            size='xs'
            renderTitle={() => {
              return <IconButton appearance='primary' icon={i18n.language === 'kz' ? <flags.KZ className='lang-flags' title='Kazakhstan' /> : i18n.language === 'en' ? <flags.GB className='lang-flags' title='England' /> : <flags.RU className='lang-flags' title='Russian' />} circle size='xs' />
            }}
          >
            <Dropdown.Item size='xs' onClick={() => { handleClick('en') }}><flags.GB className='lang-flags' title='England' /> EN </Dropdown.Item>
            <Dropdown.Item size='xs' onClick={() => { handleClick('ru') }}><flags.RU className='lang-flags' title='Russian' /> RU </Dropdown.Item>
            <Dropdown.Item size='xs' onClick={() => { handleClick('kz') }}><flags.KZ className='lang-flags' title='Kazakhstan' /> KZ</Dropdown.Item>
          </Dropdown>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  )
}
HeaderPanel.propTypes = {
  logout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user
  }
}

export default connect(mapStateToProps, { logout: actions.logout })(HeaderPanel)
