import { useState, useContext } from 'react'
import { Container } from 'rsuite'
import 'rsuite/dist/styles/rsuite-dark.css'
import Map from './map/Map'
import SatImgContextProvider from './contexts/DataProviderContext'
import SearchOptionsContextProvider from './contexts/SearchDetailsContext'
import UploadKML from './imports/UploadKML'
// import SidenavPanel from './components/Panel'
import SidenavPanel from './components/SidebarPanel'
import WelcomeModal from './components/WelcomeModal'
import NavigationContextProvider from './contexts/NavigationContext'
import AuthenticationContextProvider from './contexts/AuthenticationContext'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from 'react-router-dom'
import AccountVerification from './auth/AccountVerification'
import SignIn from './auth/SignIn'
import PasswordChangeModal from './auth/PasswordChangeModal'
import CRMDetails from './crm/CRMdetails'

function App () {
  const [modalShow, setModalShow] = useState(!localStorage.token)
  const [isTourOpen, closeTour] = useState(false)
  if (!!localStorage.token & !sessionStorage.token) {
    console.log('storage copied!')
    sessionStorage.user = localStorage.user
    sessionStorage.token = localStorage.token
    sessionStorage.locale = localStorage.locale
  }

  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route path='/verification'>
            <AccountVerification show={modalShow} onHide={() => setModalShow(false)} />
          </Route>
          <Route path='/sign_in'>
            {/* <SignIn show={modalShow} onHide={() => setModalShow(false)} /> */}
            <Redirect path='/' />
          </Route>

          <Route path='/password_reset'>
            <PasswordChangeModal show={modalShow} onHide={() => setModalShow(false)} />
          </Route>
          <Route path='/order/crm/:id'>
            <SignIn show={modalShow} onHide={() => setModalShow(false)} />
            <CRMDetails />
          </Route>
          <Route path='/'>
            <WelcomeModal show={modalShow} onHide={() => setModalShow(false)} closeTour = {closeTour}/>
            <AuthenticationContextProvider>
              <NavigationContextProvider>
                <SearchOptionsContextProvider>
                  <SatImgContextProvider>
                    <Container>
                      <Container>
                        <Map />
                      </Container>
                      <SidenavPanel closeTour = {closeTour} setModalShow = {setModalShow} isTourOpen = {isTourOpen}/>
                    </Container>
                  </SatImgContextProvider>
                </SearchOptionsContextProvider>
              </NavigationContextProvider>
            </AuthenticationContextProvider>
          </Route>
          <Route path='*'>
            <Link to='/' />
          </Route>
        </Switch>

      </div>
    </Router>
  )
}

export default App
