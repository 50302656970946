import L from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw-src.css'
import 'leaflet-draw'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'

function getSearchControlFunctions (map, searchControl) {
  let isSet = false
  return {
    removeSearchControl: function () {
      map.removeControl(searchControl)
      isSet = false
    },
    addSearchControl: function () {
      if (!isSet) {
        map.addControl(searchControl)
        isSet = true
      }
    }
  }
}

export default function MapControls (map, t) {
  try {
    L.drawLocal.draw.toolbar = {
      ...L.drawLocal.draw.toolbar,
      // #TODO: this should be reorganized where actions are nested in actions
      // ex: actions.undo  or actions.cancel
      actions: {
        title: 'Cancel drawing',
        text: 'Cancel'
      },
      finish: {
        title: 'Finish drawing',
        text: 'Finish'
      },
      undo: {
        title: 'Delete last point drawn',
        text: 'Delete last point'
      },
      buttons: {
        // polygon: 'Draw a polygon - Нарисовать многоугольник - Көпбұрыш сызу',
        polygon: t('leaflet.draw.buttons.polygon'),
        rectangle: t('leaflet.draw.buttons.rectangle')
      }
    }
    // add the address search bar
    const provider = new OpenStreetMapProvider()
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: false,
      style: 'bar',
      searchLabel: t('leaflet.searchlabel')
    })
    // map.addControl(searchControl)
    const searchControlFunctions = getSearchControlFunctions(map, searchControl)
    searchControlFunctions.addSearchControl()
    // L.Control.Search = L.Control.extend({
    //   // options: {
    //   //     position: 'topleft',
    //   // },
    //   onAdd: function (map) {
    //     const provider = new OpenStreetMapProvider()
    //     const searchControl = new GeoSearchControl({
    //       provider: provider,
    //       showMarker: false,
    //       style: 'bar',
    //     })
    //     return searchControl;
    //   }
    // });
    // L.control.search = function (options) {
    //   return new L.Control.Search(options);
    // };
    // L.control.search({ position: 'bottomleft' }).addTo(map)
    L.control.scale().addTo(map)
    L.control.zoom({
      zoomInText: '+',
      zoomInTitle: t('leaflet.zoomin'),
      zoomOutText: '-',
      zoomOutTitle: t('leaflet.zoomout')
    }).addTo(map)

    // Initialise the FeatureGroup to store editable layers
    const editableLayers = new L.FeatureGroup()
    map.addLayer(editableLayers)

    const drawPluginOptions = {
      // position: 'topright',
      draw: {
        // polyline: {
        //   shapeOptions: {
        //     color: '#f357a1',
        //     weight: 10
        //   }
        // },
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#7D7C7A', // '#010101', // Color the shape will turn when intersects
            message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#7D7C7A' // '#fefefe'
          }
        },
        circle: false, // Turns off this drawing tool
        rectangle: {
          shapeOptions: {
            clickable: false,
            color: '#7D7C7A' // '#fefefe'
          }
        },
        marker: false,
        circlemarker: false,
        polyline: false
      }
      // edit: {
      //   featureGroup: editableLayers, // REQUIRED!!
      //   remove: false
      // }
    }
    // Initialise the draw control and pass it the FeatureGroup of editable layers
    const drawControl = new L.Control.Draw(drawPluginOptions)
    map.addControl(drawControl)

    L.Control.Watermark = L.Control.extend({
      onAdd: function (map) {
        const img = L.DomUtil.create('img')
        img.src = '../../../img/LOGO_KGS_New@2x.png'
        img.style.width = '100px'
        return img
      },
      onRemove: function (map) {
        // Nothing to do here
      }
    })
    L.control.watermark = function (opts) {
      return new L.Control.Watermark(opts)
    }
    L.control.watermark({ position: 'bottomleft' }).addTo(map)
    return getSearchControlFunctions(map, searchControl)
  } catch (e) {
    console.log(e)
  }
}
