/**
 * The bundles here are configured so that each locale only requires loading a single webpack chunk.
 */

const bundles = {
  en: () => import(/* webpackChunkName: "en" */ './locales/en.json'),
  ru: () => import(/* webpackChunkName: "es" */ './locales/ru.json'),
  kz: () => import(/* webpackChunkName: "zh" */ './locales/kz.json')
}

// generate whitelist for i18next
export const availableLocales = Object.keys(bundles)

export default bundles
