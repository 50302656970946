import React, { useState } from 'react'
import { Icon, List, Button, Input } from 'rsuite'
import L from 'leaflet'

const Cadastr = ({ clearMap, isVisible, addToMap }) => {
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const divClassName = isVisible ? 'cadastrVisible' : 'hidden'

  const handleInputChange = (event) => {
    const value = event.target.value
    setInputValue(value)

    if (value.length >= 3) {
      fetchSuggestions(value)
    } else {
      setSuggestions([])
    }
  }

  const fetchSuggestions = async (value) => {
    try {
      const response = await fetch(`https://eo.gharysh.kz/api/v1/cadastral_search?cadastral_number=${value}`)
      const data = await response.json()
      setSuggestions(data)
    } catch (err) {
      console.error('Error fetching results', err)
    }
  }

  const handleSubmit = (gzkData) => {
    const feature = {
      type: 'Feature',
      geometry: gzkData.geom_raw
    }
    addToMap(feature)
  }

  const pickCadastr = (suggestion) => {
    setInputValue(suggestion.cadastral_number)
    setSuggestions([])
    handleSubmit(suggestion)
  }

  const reset = (e) => {
    e.preventDefault()
    setInputValue('')
  }

  return (
    <div className={divClassName}>
      <form style={{ textAlign: 'center' }}>
        <span className='cadastr-input-with-btn'>
          <input type="text" value={inputValue} placeholder='Введите кадастровый номер' onChange={handleInputChange} onSubmit={handleSubmit}></input>
          <button className='reset' onClick={ reset }><Icon icon={'close'}/></button>
        </span>
        <div className='results'>
          <List>
            {suggestions.map((suggestion, index) => (
              <List.Item key={index}><div onClick={() => pickCadastr(suggestion)}>{suggestion.cadastral_number}</div></List.Item>
            ))}
          </List>
        </div>
      </form>
    </div>
  )
}

export default Cadastr
