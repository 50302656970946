import React, { useContext, useState, useEffect } from 'react'
import { FlexboxGrid, Icon, Button, Panel, DateRangePicker, CheckPicker, CheckboxGroup, Checkbox, Radio, RadioGroup } from 'rsuite'
import moment from 'moment'
import useModal from '../../modal/useModal'
import SearchFilterModal from '../../modal/SearchFilterModal'
import { SearchOptionsContext } from '../../../contexts/SearchDetailsContext'
import { SatImgContext } from '../../../contexts/DataProviderContext'
import { useTranslation } from 'react-i18next'
import data from '../../../data/satellites.json'
import SearchFilterSettings from './SearchFilterSettings'

const SearchSettings = () => {
  const { t } = useTranslation()
  const { isShowing, toggle } = useModal()
  const { searchOptions, setSearchOptions } = useContext(SearchOptionsContext)
  const { dataTiles, setDataTiles } = useContext(SatImgContext)
  const [scenes, setScenes] = useState(null)
  const [scenesHR, setScenesHR] = useState([])
  const [scenesMR, setScenesMR] = useState([])
  const [scenesST, setScenesST] = useState([])
  const [sat, setSat] = useState([])

  /**
   * backing up scenes for fast refiltering
   */
  useEffect(() => {
    if (scenes === null || (dataTiles?.length > 0 && dataTiles?.length > scenes?.length)) {
      setScenes(() => dataTiles)
      setSat(['KazEOSat-1', 'KazEOSat-2', 'KazSTSat'])
    }
  }, [dataTiles])

  /**
   * setting every satellite scenes seperately every time backup scenes updates
   */
  useEffect(() => {
    setScenesHR(() => scenes?.filter(item => item.satellite === 'KazEOSat-1'))
    setScenesMR(() => scenes?.filter(item => item.satellite === 'KazEOSat-2'))
    setScenesST(() => scenes?.filter(item => item.satellite === 'KazSTSat'))
  }, [scenes])

  const handleSatelliteFilter = (value) => {
    setSat(value)
    console.log(value)
    console.log(scenesHR, scenesMR, scenesST)
    let list = []
    if (value.includes('KazEOSat-1') && scenesHR.length > 0) {
      list = [...list, ...scenesHR]
    }
    if (value.includes('KazEOSat-2') && scenesMR.length > 0) {
      list = [...list, ...scenesMR]
    }
    if (value.includes('KazSTSat') && scenesST.length > 0) {
      list = [...list, ...scenesST]
    }
    setDataTiles(list)
  }
  useEffect(() => {
    console.log(dataTiles)
  }, [dataTiles])
  return (
    <Panel bordered className='shrink-panel'>
      <FlexboxGrid justify='space-between'>
      <FlexboxGrid.Item style={{ maxWidth: 'fit-content', maxHeight: '60px' }}>
      <CheckboxGroup inline name="Satellite" placeholder='Satellite' id='radio-sat' value={sat} onChange={value => handleSatelliteFilter(value)} className='checkbox-list'>
        {['KazEOSat-1', 'KazEOSat-2', 'KazSTSat'].map(item => (
          <Checkbox key={item} value={item}>
            {item}
          </Checkbox>
        ))}
      </CheckboxGroup>
      < hr />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          {/* <Button size='xs' style={{ width: '100%' }} show={isShowing} onClick={toggle}>
            <Icon icon='setting' /> {t('Common.filter')}
          </Button> */}
          <SearchFilterSettings/>
        </FlexboxGrid.Item>
        {/* <FlexboxGrid.Item colspan={13}>
          <DateRangePicker
            showOneCalendar
            size='xs'
            placeholder={`${searchOptions.date_from} - ${searchOptions.date_to}`}
            onChange={value => {
              setSearchOptions({
                satellite: searchOptions.satellite,
                date_from: moment(value[0]).format('YYYY-MM-DD'),
                date_to: moment(value[1]).format('YYYY-MM-DD'),
                geometry: searchOptions.geometry,
                cloud_coverage: 100
              })
              console.log(searchOptions)
            }}
            placement='bottomStart'
          />
        </FlexboxGrid.Item> */}
      </FlexboxGrid>
    </Panel>
  )
}

export default SearchSettings
