import { useContext, useEffect, useState } from 'react'
import { Modal, Form, Button, Uploader, Alert } from 'rsuite'
import tj from '@mapbox/togeojson'
import JSZip from 'jszip'
import L from 'leaflet'
import shp from 'shpjs'
import * as turf from '@turf/turf'
import { NavigationContext } from '../contexts/NavigationContext'
import { SearchOptionsContext } from '../contexts/SearchDetailsContext'
import { SatImgContext } from '../contexts/DataProviderContext'
import { useTranslation } from 'react-i18next'
import { parse } from 'date-fns'
import { geometry } from '@turf/turf'

const UploadFilesModal = (props) => {
  const { t } = useTranslation()
  const [searchLayer, setSearchLayer] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { jsonLayers, setJsonLayers, geoJsonParser } = useContext(SearchOptionsContext)
  const { searchOptions, setSearchOptions, newOrder } = useContext(SearchOptionsContext)
  const { setNavItemId } = useContext(NavigationContext)
  const [file, setFile] = useState()
  const { dataTiles, fetchDataTiles, activeTile, setActiveTile, chosenTiles, setChosenTiles } = useContext(SatImgContext)
  const kmlUpload = (e) => {
    if (e && e.length > 0) {
      setFile(() => e)
      const filename = e[0].name
      const lastDot = filename.lastIndexOf('.')
      const ext = filename.slice(lastDot + 1)
      const f = e[0].blobFile
      const r = new FileReader()
      const options = {
        stroke: true,
        color: 'white',
        weight: 3,
        opacity: 0.5,
        fill: true,
        fillColor: 'white',
        fillOpacity: 0.1,
        clickable: true
      }
      r.addEventListener('load', async (e) => {
        let convertedKml = ''
        if (ext === 'kml') {
          const kml = new DOMParser().parseFromString(e.target.result, 'text/xml')
          convertedKml = tj.kml(kml)
        } else if (ext === 'kmz') {
          const newZip = new JSZip()
          const contents = await newZip.loadAsync(e.target.result)
          const content = await contents.file('doc.kml').async('string')
          convertedKml = tj.kml(
            new DOMParser().parseFromString(content, 'text/xml')
          )
        } else if (ext === 'geojson') {
          convertedKml = JSON.parse(e.target.result)
        } else {
          // this.toaster.current.configErrorResponse({
          //   message: 'FormatNotAllowed'
          // })
          console.log('FormatNotAllowed')
          return
        }

        if (convertedKml.type === 'Feature') {
          convertedKml = {
            type: 'FeatureCollection',
            features: [convertedKml]
          }
        }

        const layers = geoJsonParser(convertedKml)
        setSearchLayer(layers)
        console.log(convertedKml)
        if (!layers) return Alert.error(t('Errors.wrong'))
        if (layers) {
          let convertedKmlFilter = {}
          convertedKml.features.forEach(feat => {
            console.log(feat)
            if (feat.geometry.type === 'Polygon') {
              convertedKmlFilter = feat
            } else if (feat.geometry.type === 'GeometryCollection') {
              convertedKmlFilter = {
                type: 'Feature',
                geometry: feat.geometry.geometries[0]
              }
            }
          })
          let area = 0
          L.geoJSON(convertedKml).getLayers().forEach(e => {
            if (e.feature.geometry.type === 'GeometryCollection') {
              e.feature.geometry.geometries.forEach(r => {
                area += L.GeometryUtil.geodesicArea(L.geoJSON(r).getLayers()[0].getLatLngs()[0])
              })
            } else {
              console.log(e.getLatLngs()[0])
              area += L.GeometryUtil.geodesicArea(e.getLatLngs()[0])
            }
          })
          props.setArea((area / 10000).toFixed(3))
          setJsonLayers(convertedKml)
          setActiveTile(convertedKmlFilter, true)
          // if (jsonLayers?.type !== convertedKml.type) {
          //   kmlUpload(file)
          //   Alert.error('Непредвиденная ошибка! Попробуйте загрузить KML еще раз!')
          // }
        }
        props.onHide()
      })
      r.readAsBinaryString(f)
      e = ''
    }
  }

  const shpUpload = (e) => {
    const shpFiles = e[0]
    const name = shpFiles.blobFile.name
    if (name.includes('.zip') === true) {
      const zipFile = shpFiles.blobFile
      parseZipShp(zipFile)
    } else {
      parseShp(shpFiles.blobFile)
    }
  }

  const gjsonUpload = (e) => {
    const jsonfile = e[0]
    const name = jsonfile.blobFile.name
    parseGeoJSON(jsonfile.blobFile)
  }

  const parseZipShp = (zipFile) => {
    const fr = new FileReader()
    fr.readAsArrayBuffer(zipFile)
    fr.onload = zipReceiveBinary
    // check syntax
  }

  const zipReceiveBinary = () => {
    const fr = new FileReader()
    const result = fr.result
    const shpfile = shp(result)
  }

  const parseShp = (shpFiles) => {
    let dbfContent = {}
    let shpContent = {}
    if (shpFiles.name.includes('.dbf') === true) {
      loadLocalFile(shpFiles, function (content) {
        dbfContent = content
        if (shpContent && dbfContent) {
          loadShapefile(shpContent, dbfContent)
        }
      })
    } else if (shpFiles.name.includes('.shp') === true) {
      loadLocalFile(shpFiles, function (content) {
        shpContent = content
        if (shpContent && dbfContent) {
          loadShapefile(shpContent, dbfContent)
        }
      })
    }
  }
  const parseGeoJSON = (files) => {
    console.log(files)
    loadLocalFile(files, function (content) {
      getJSONLayer(content)
    })
  }

  const loadShapefile = (shp, dbf) => {
    let convertedShp = ''
    const shapefile = require('shapefile')
    const geojson = shapefile.open(shp)
      .then(source => source.read()
        .then(function log (result) {
          if (result.done) return
          convertedShp = result.value
          if (convertedShp.type === 'Feature') {
            convertedShp = {
              type: 'FeatureCollection',
              features: [convertedShp]
            }
            getShpLayer(convertedShp)
          }
          return source.read().then(log)
        }))
      .catch(error => console.error(error.stack))
  }

  const getShpLayer = (convertedShp) => {
    const layers = geoJsonParser(convertedShp)
    console.log(layers)
    setSearchLayer(layers)
    if (!layers) return Alert.error(t('Errors.wrong'))
    if (layers) {
      setJsonLayers(convertedShp)
      setActiveTile(convertedShp.features[0], true)
    }
    props.onHide()
  }
  const getJSONLayer = (l) => {
    console.log(l)
    const m = l.replaceAll('\'', '\"')
    console.log(m)
    const layer = JSON.parse(m)
    setSearchLayer(layer)
    // if (!layers) return Alert.error(t('Errors.wrong'))
    setJsonLayers(layer)
    setActiveTile(layer, true)
    props.onHide()
  }

  const loadLocalFile = (file, callback) => {
    const modelShape = {
      coordinates: null,
      content: null
    }
    let content = modelShape.content
    if (typeof FileReader === 'function') {
      const reader = new FileReader()
      reader.onload = function (e) {
        content = e.target.result
        callback.call(this, content)
      }
      console.log(file)
      if (file.name.includes('json')) {
        reader.readAsText(file)
      } else {
        reader.readAsArrayBuffer(file)
      }
    } else {
      console.log('Error')
    }
  }
  useEffect(() => {
    if (!newOrder) {
      if (!(searchLayer && Object.keys(searchLayer).length === 0 && searchLayer.constructor === Object)) {
        // fetchData()
        setIsLoading(true)

        fetchDataTiles(searchOptions, setIsLoading)
      }
    }
  }, [searchOptions])

  useEffect(() => {
    setSearchOptions({
      satellite: searchOptions.satellite,
      date_from: searchOptions.date_from,
      date_to: searchOptions.date_to,
      geometry: searchLayer?.geometry,
      cloud_coverage: searchOptions.cloud_coverage
    })
  }, [searchLayer])

  return (
    <Modal {...props} show={props.show}>
      <Modal.Header>
        <Modal.Title>
        {t('Common.upload')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Uploader
            accept='.kml, .kmz, .geojson, .zip'
            type='file'
            onChange={kmlUpload}
          >
            <Button>{t('Common.Upload.kml')}</Button>
          </Uploader>
          <Uploader
            accept='.shp, .zip'
            type='file'
            onChange={shpUpload}
          >
            <Button>{t('Common.Upload.shp')}</Button>
          </Uploader>
          <Uploader
            accept='.geojson, .json'
            type='file'
            onChange={gjsonUpload}
          >
            <Button>Загрузить GeoJSON</Button>
          </Uploader>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t('Common.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadFilesModal
