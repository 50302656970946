import React, { useState, useContext } from 'react'
import { Sidebar, Panel, Sidenav, Button } from 'rsuite'
import SideNavMenu from './sidebarpanels/SidenavMenu'
import SearchSidenav from './sidebarpanels/SearchSidenav'
import OrdersHistorySidenav from './sidebarpanels/OrderHistorySidenav'
import DashboardSidenav from './sidebarpanels/DashboardSidenav'

import { NavigationContext } from '../contexts/NavigationContext'
import HeaderPanel from './sidebarpanels/dashboardelements/HeaderTop'
import NewOrderSidenav from './sidebarpanels/NewOrderSidenav'
import Cart from './sidebarpanels/cart/Cart'
import Tour from 'reactour'
import { useTranslation } from 'react-i18next'

const SidenavPanel = (props) => {
  const { t } = useTranslation()
  const now = new Date()
  // const regdate = new Date(JSON.parse(localStorage?.user)?.created_at) | undefined
  const steps = [
    {
      selector: '.leaflet-draw-section',
      content: t('tour.first')
    },
    {
      selector: '#second-step',
      content: t('tour.second')
    },
    {
      selector: '#third-step',
      content: t('tour.third')
    },
    {
      selector: '#fourth-step',
      content: t('tour.fourth')
    },
    {
      selector: '#fifth-step',
      content: t('tour.fifth')
    },
    {
      selector: '#sixth-step',
      content: t('tour.sixth')
    }
  ]
  const { navItemId, setNavItemId } = useContext(NavigationContext)
  const heightHeaderTop = 56
  const heightOperationBlock = window.innerHeight - heightHeaderTop * 1.2

  const [expand, setExpand] = useState(false)
  const switchStep = (curr) => {
    curr === 2 && setNavItemId(3)
    curr === 3 && setNavItemId(4)
    curr === 4 && setNavItemId(1)
    curr === 5 && setNavItemId(0)
  }
  const handleExpand = () => {
    setExpand(() => !expand)
  }

  return (
    <>
      <Sidebar
        className='d-side-new'
        width={expand ? 400 : 0}
        height={expand ? window.innerHeight : 0}
        collapsible
      >
        <Sidenav.Header style={{ display: expand ? 'block' : 'none' }}>
          <HeaderPanel height={heightHeaderTop} />
        </Sidenav.Header>
        <Panel appearance='subtle' className='d-side-new' bodyFill style={{ height: heightOperationBlock }}>
          {
            {
              0: <DashboardSidenav />,
              1: <OrdersHistorySidenav height={heightOperationBlock} />,
              2: <SearchSidenav setModalShow={props.setModalShow} height={heightOperationBlock} />,
              3: <NewOrderSidenav height={heightOperationBlock} />,
              4: <Cart height={heightOperationBlock} />
              // 4: <Cart><h3>Settings</h3></Cart>
            }[navItemId]
          }
        </Panel>
      </Sidebar>

      <Sidebar
        width={56}
        collapsible
      >
        <SideNavMenu expand={expand} handleExpand={handleExpand} setModalShow={props.setModalShow} closeTour={props.closeTour}/>
      </Sidebar>
      <Tour
        className='tour'
        steps={steps}
        isOpen={props.isTourOpen}
        accentColor={'#169de0'}

        // nextStep={nextStep}
        // getCurrentStep={(n) => setStep(n)}
        onAfterOpen={() => { setNavItemId(2); setExpand(true) }}
        onBeforeClose={() => { setNavItemId(0); setExpand(false) }}
        getCurrentStep={(curr) => switchStep(curr)}
        onRequestClose={() => props.closeTour(false)}><Button className='tour-button' onClick={() => props.closeTour(false)}>{t('Common.cancel')}</Button></Tour>
    </>
  )
}

export default SidenavPanel
