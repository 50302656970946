import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'rsuite'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../api'

const AccountVerification = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const loc = useLocation()
  console.log('test')
  const onSubmit = async () => {
    setLoading(true)
    const token = loc.pathname.split('/').pop()
    await api.user.verify(token).then((res) => {
      setLoading(false)
      // history.push('/sign_in')
    })
  }

  return (
    <div className='show-grid'>
      <Modal {...props} show={props.show} backdrop={false}>
        <Modal.Header>
          <Modal.Title className='text-center'>
            Верификация
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('Auth.Registartion.verification')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance='primary' size='sm' onClick={onSubmit} loading={loading} className='signIn'
          >
            Нажмите для подтверждения аккаунта
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountVerification
