import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react/'
import { Modal, Button, FlexboxGrid, Form, Input, InputGroup, Icon, Alert } from 'rsuite'
import API from '../api'
import { useLocation, useParams } from 'react-router-dom'
import { login } from '../actions/auth'

const PasswordChangeModal = (props) => {
  const { t } = useTranslation()
  const loc = useLocation()
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        handlePasswordChange()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')

  // const [ errors, setErrors ] = useState([])
  const [loading, setLoading] = useState(false)

  const handlePasswordChange = () => {
    const token = loc.pathname.split('/').pop() // getting last element of splitted url stting
    console.log(token)
    const tempErrors = validatePassword(password1) || validatePassword(password2)
    if ((Object.keys(tempErrors).length === 0) || (tempErrors === 1)) {
      const fetchData = async (data) => {
        console.log()
        const result = await API.user.reset(data)
        if (!result.success) {
          Alert.error(result.error.message)
        } else {
          Alert.success(t('Auth.ResetPassword.success_change'))
          await API.user.login({
            email: result.email,
            password: password1
          }).then(e => {
            // e.user.token = e.auth_token
            localStorage.user = JSON.stringify(e.user)
            sessionStorage.token = e.auth_token
            localStorage.token = e.auth_token
            sessionStorage.user = JSON.stringify(e.user)
            window.location.href = '/'
          })
        }
      }
      fetchData({ token, password: password1 })
    }
  }

  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }

  const validatePassword = (data) => {
    const message = {}
    if (!data) {
      message.error = t('Errors.empty_password')
    } else {
      // eslint-disable-next-line prefer-regex-literals
      const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$', 'g')
      if (!passwordPattern.test(data)) {
        message.error = t('Errors.valid_password')
      }
    }
    if (message.error) Alert.error(message.error)
    return message.error || 1
  }

  return (
    <div className='show-grid'>
      <Modal {...props} show={props.show} backdrop='static'>
        <Modal.Body>
          <Form>

            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <div>
                  <h4 className='text-center'> {t('Auth.ResetPassword.reset_message')}</h4><br />
                  <h6 className='color-highlight'> {t('Auth.Password.password_requirements')}</h6><br />
                  <p>{t('Auth.Password.tooltip')}</p>
                </div>
                <br />
                <InputGroup inside>
                  <Input
                    placeholder='Enter your new password*' size='md'
                    id={data.password}
                    type={passwordType}
                    onChange={e => setPassword1(e)}
                  />
                  <InputGroup.Addon>
                    <Icon link icon={eye} onClick={togglePassword} />
                  </InputGroup.Addon>
                </InputGroup>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>

                <InputGroup inside>
                  <Input
                    placeholder='Confirm your new password*' size='md'
                    id={data.password}
                    type={passwordType}
                    onChange={e => setPassword2(e)}
                  />
                  <InputGroup.Addon>
                    <Icon link icon={eye} onClick={togglePassword} />
                  </InputGroup.Addon>
                </InputGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance='primary' color='green' size='sm' loading={loading} onClick={handlePasswordChange}>
            {t('Common.ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PasswordChangeModal
