import { useContext, useState, useEffect } from 'react'
import { Sidenav, Panel, FlexboxGrid, Icon, Badge, Avatar, Button, Alert } from 'rsuite'
import ResultsTable from './searchelements/SearchResultsList'
import { SatImgContext } from '../../contexts/DataProviderContext'
import SearchSettings from './searchelements/SearchSettings'
import { Trans, useTranslation } from 'react-i18next'
import { SearchOptionsContext } from '../../contexts/SearchDetailsContext'
import tokml from 'tokml'

const SearchSidenav = (props) => {
  const { t } = useTranslation()
  const { dataTiles, activeTile, chosenTiles } = useContext(SatImgContext)
  const { setNewOrder, shareTile } = useContext(SearchOptionsContext)
  const [totalResults, setTotalResults] = useState(0)

  useEffect(() => {
    setNewOrder(() => { return false })
  }, [])

  useEffect(() => {
    setTotalResults(dataTiles?.length)
  }, [dataTiles?.length])
  const downloadTiles = (tiles) => {
    if (!shareTile) {
      Alert.error('Сначала выделите область интереса!')
    } else {
      console.log(tiles)
      const kmlString = tokml(tiles)
      // let kmlString = `
      // <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
      // <Document>
      // `
      // for (const tile of tiles) {
      //   console.log(tile)
      //   const imgCoords = [
      //     tile.geometry.coordinates[0][0],
      //     tile.geometry.coordinates[0][3],
      //     tile.geometry.coordinates[0][2],
      //     tile.geometry.coordinates[0][1],
      //     tile.geometry.coordinates[0][4]
      //   ]
      //   const parsedImg = imgCoords.map(el => {
      //     el = [el[0], el[1] + ',0']
      //     return el
      //   })
      //   const parsedCoordinates = tile.geometry.coordinates.flat(1).map(el => {
      //     el = [el[0], el[1] + ',0']
      //     return el
      //   })
      //   console.log(parsedCoordinates)
      //   // var conv = tokml(tile)
      //   kmlString += `
      //   <Folder>
      //     <name>${tile.code}</name>
      //     <description>
      //     Date: ${tile.meta_date}, Satellite: ${tile.satellite}, Resolution: 1.0</description>
      //     <Placemark>
      //       <Polygon>
      //         <outerBoundaryIs>
      //           <LinearRing>
      //             <coordinates>
      //             ${parsedCoordinates.join('\n')}
      //             </coordinates>
      //           </LinearRing>
      //         </outerBoundaryIs>
      //       </Polygon>
      //     </Placemark>
      //     <GroundOverlay>
      //       <Icon>
      //         <href>
      //         https://eo.gharysh.kz${activeTile.quicklook_url}
      //         </href>
      //       </Icon>
      //     <gx:LatLonQuad xmlns:gx="http://www.google.com/kml/ext/2.2">
      //       <coordinates>
      //       ${parsedImg.join('\n')}
      //       </coordinates>
      //     </gx:LatLonQuad>
      //     </GroundOverlay>
      //     </Folder>
      // `
      // }
      // kmlString += `
      // </Document>
      // </kml>
      // `
      // console.log(kmlString)
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(new Blob([kmlString], { type: 'application/vnd.google-earth.kml+xml' }))
      a.download = 'aoi.kml'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
  const handleClick = () => {
    downloadTiles(shareTile)
  }

  return (
    <Sidenav appearance='subtle' className='p-15' id='second-step'>
      <Sidenav.Body style={{ height: props.height * 8 / 10 }}>
        <h6 className='p-0'>{t('Search.title')}</h6>
        <SearchSettings className='shrink-panel'/>
        <br />
        <Panel
          header={
            <div>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={15}>
                  <div>
                    <Trans
                      i18nKey='Search.results_info'
                      values={{
                        totalResults: totalResults
                      }}
                      defaults='Search yielded <bold>{{totalResults}}</bold> results'
                      components={{ bold: <strong /> }}
                    />
                  </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                  <div>
                    <Button size='xs' appearance='ghost' onClick={() => handleClick()}>
                      <Icon icon='share' />
                      {t('Common.share')}
                    </Button>
                  </div>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
          }
          bordered
          bodyFill
          className='new-scroll'
        >
          <ResultsTable data={dataTiles} setModalShow={props.setModalShow}/>
        </Panel>
      </Sidenav.Body>
    </Sidenav>
  )
}

export default SearchSidenav
