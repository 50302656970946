import { GET_CART, NEW_CART_ITEM, DELETE_CART, CARTS_POSTED } from '../types'
export default (state = {}, action = {}) => {
  switch (action.type) {
    case GET_CART:
      return action.cart ? action.cart : null
    case NEW_CART_ITEM:
      return state
    case DELETE_CART:
      return state
    case CARTS_POSTED:
      return state
    default:
      return state
  }
}
